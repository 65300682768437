import { Grid, LinearProgress, Typography, Button } from "@material-ui/core";
import Spinner from "../../../template/Spinner";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@material-ui/data-grid";
import { SaveAlt } from "@material-ui/icons";
import React from "react";
import UserDescriptionHeader from "../reports-students/reports/report-students-extended/user-description-header";
import InfiniteScroll from "react-infinite-scroll-component";
import OrganizationService from "../../../../services/OrganizationService";
import AsyncButton from "../../../template/AsyncButton";
import ProgramService from "../../../../services/ProgramService";
import Error from "../../../template/Error";
import { Redirect } from "react-router-dom";

// max column width in pixels
const maxCellWidth = 250;

/**
 * @class ReportStudents
 */
class ReportStudents extends React.Component {
  state = {
    userToRender: null,
    allStudents: null,
    programs: null,
    error: null,
  };
  componentDidMount() {
    this.fetchPrograms();
    if (this.props.studentsprogress && this.props.students) {
      this.setState({ userToRender: null }, this.fetchNext);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.students !== this.props.students) {
      this.fetchPrograms();
    }
    if (
      this.props.studentsprogress &&
      this.props.students &&
      (prevProps.students !== this.props.students ||
        prevProps.studentsprogress !== this.props.studentsprogress)
    ) {
      this.setState({ userToRender: null }, this.fetchNext);
    }
    // when we do a component did mount the fetchPrograms will set allStudents value after the fetchNext accure
    // that couse fetchNext to not fetch any students
    // so we need to after the allStudents get set to fetch the next students again
    if (
      Array.isArray(this.state.allStudents) &&
      !Array.isArray(prevState.allStudents)
    ) {
      this.setState({ userToRender: null }, this.fetchNext);
    } else if (
      prevProps.students !== this.props.students ||
      prevProps.studentsprogress !== this.props.studentsprogress
    ) {
      this.setState({ userToRender: null });
    }
  }
  fetchNext = () => {
    const { allStudents } = this.state;
    console.log(allStudents);
    let userToRender = [...(this.state.userToRender || [])];
    const numberOfUsers = Math.min(
      userToRender.length + 5,
      (allStudents || []).length
    );
    userToRender = Array.isArray(allStudents)
      ? allStudents.slice(0, numberOfUsers)
      : [];
    this.setState({ userToRender }, () => console.log(userToRender));
  };
  async fetchPrograms() {
    const programs = await ProgramService.getPrograms();
    if (!programs) {
      this.setState({
        error: ProgramService.error || `Unknown error fetching programs`,
      });
      return;
    }
    //fetch all the organization programs and then ready the report data
    this.setState({ programs }, this.populatestudentPrograms);
  }
  populatestudentPrograms = () => {
    const { students, studentsprogress } = this.props;
    const { programs } = this.state;
    if (
      Array.isArray(programs) &&
      Array.isArray(students) &&
      Array.isArray(studentsprogress)
    ) {
      const allStudents = [...students].map((student) => {
        // create the user report data
        let programs_grades = [];
        if (Array.isArray(student.programs)) {
          //create a single table
          student.programs.map((program) => {
            const current_program = programs.find(
              (p) => p.program_id === program.program_id
            );
            let columns = [];
            let rows = [];
            //if current program is found and has courses then create the report table
            if (current_program && Array.isArray(current_program.courses)) {
              //create the single table columns
              columns = [
                ...current_program.courses.map((course) => {
                  return { title: course.title, code: course.course_code };
                }).sort((a, b) => a.code.localeCompare(b.code)),
              ].map((obj) => {
                return {
                  field: obj.code,
                  headerName: obj.title,
                  sortable: false,

                  // set the width of the column to fit the course title
                  // 9.5 is just a constant, found by hand - it works well with this font size.
                  // Limit the width by maxCellWidth value
                  width: Math.min(
                    obj.title ? obj.title.length * 9.5 : 200,
                    maxCellWidth
                  ),
                };
              });
              //console.log("columns",columns);
              //create the single table row
              let row = { id: `${student.id}-${program.program_id}` };
              let final_grade = 0;
              let count = 0;
              // console.log(studentsprogress)
              // get row data grades
              if (Array.isArray(studentsprogress)) {
                current_program.courses.map((course) => {
                  // find course final grade
                  const course_grade = // find current student's progress
                    // get the course progress array
                    (
                      (
                        (
                          studentsprogress.find(
                            (s) => s.user_id === student.id
                          ) || {}
                        ).course_progress || []
                      ) // ...or fallback to empty object
                        // TODO: check if it's an array, probable place for a bug
                        // find the current course progress (or fallback to empty object)
                        .find((c) => c.course_id === course.course_id) || {}
                    ).total_score; // get the course grade

                  // if the course grade is found then add it to the row
                  row = {
                    ...row,
                    [course.course_code]: Math.round(course_grade) || "",
                  };
                  // if the course grade is found then add it to the final grade
                  if (course_grade) {
                    final_grade += course_grade;
                    count += 1;
                  }
                });
              }
              row = {
                ...row,
                [program.program_id]:
                  count == 0 ? "" : final_grade / count || "",
              };
              //add the row to the table rows
              rows = [...rows, row];
              //add the columns and rows to the table
              programs_grades = [
                ...programs_grades,
                {
                  columns,
                  rows,
                  title: program.title,
                  grade: count == 0 ? "" : final_grade / count || "",

                  // program is DONE when all the courses are done (have final score)
                  done: count > 0 && count == current_program.courses.length,
                },
              ];
            }
          });
        }
        // puting all the user tables inside the user object
        return {
          ...student,
          programs_grades,
        };
      });
      //all the student after modifications
      this.setState({ allStudents }, () =>
        console.log("allStudents", allStudents)
      );
    }
    //console.log("ReportPrograms",studentPrograms);
  };
  fetchReport = async () => {
    const { studentsprogress } = this.props;
    const { allStudents } = this.state;
    const data = {
      users: allStudents,
      course_progress: studentsprogress,
    };
    const result = await OrganizationService.gradesReportExcel(data);
    const { onShowMessage } = this.props;
    if (!result) {
      onShowMessage(
        OrganizationService.error || `Unknown error fetching report`,
        "error"
      );
      return false;
    }
    const ab = new ArrayBuffer(result.data.length);
    const view = new Uint8Array(ab);
    for (let i = 0; i < result.data.length; ++i) {
      view[i] = result.data[i];
    }
    //console.log(ab,result.data[0]);
    const blob = new Blob([ab]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "GRADE_REPORT.xlsx");
    link.click();
    window.URL.revokeObjectURL(url);
    console.log("your file has downloaded!");
  };
  render() {
    const { students, studentsprogress, report } = this.props;
    const { userToRender, error, programs } = this.state;
    if (report && !error && !(students && studentsprogress)) {
      return <Redirect to={"/reports"} />;
    }
    return (
      <div class="grade-report">
        {error && <Error error={error} />}
        {!error && !(userToRender && programs) && <Spinner />}
        {!error && userToRender && programs && (
          <>
            <Typography
              className="grade-report-header"
              variant="h1"
              color="primary"
            >
              Grade report
              <AsyncButton
                variant="contained"
                // className="add-button bg-orange color-white"
                color="primary"
                onClick={this.fetchReport}
                icon={<SaveAlt />}
                size="small"
              >
                Export to Excel
              </AsyncButton>
            </Typography>
            {!userToRender && <Spinner />}
            <InfiniteScroll
              dataLength={(userToRender || []).length}
              next={this.fetchNext}
              hasMore={(userToRender || []).length < (students || []).length}
              loader={<Spinner />}
            >
              {studentsprogress &&
                Array.isArray(userToRender) &&
                userToRender.map((user) => (
                  <div class="user-grades">
                    <UserDescriptionHeader {...user} />
                    <Grid container>
                      {Array.isArray(user.programs_grades) &&
                        user.programs_grades.map((program, ind) => (
                          <Grid item xs={12}>
                            <div className="program-title">
                              <span className="title">{program.title}</span>
                              {program.done && (
                                <span>
                                  Final Grade:{" "}
                                  <span className="final-grade">
                                    {Math.round(program.grade)}
                                  </span>
                                </span>
                              )}
                            </div>
                            {/* <div>
                              {console.log("test", program.grade)}
                              <span>{program.title}</span>
                              {parseInt(program.grade) > 0 && (
                                <span>
                                  {" "}
                                  final grade: {Math.round(program.grade)}
                                </span>
                              )}
                            </div> */}
                            <DataGrid
                              loading={!Boolean(program.rows)}
                              columns={program.columns}
                              rows={program.rows}
                              pageSize={7}
                              autoHeight
                              disableSelectionOnClick
                              hideFooterPagination={true}
                              disableColumnMenu={true}
                            />
                          </Grid>
                        ))}
                    </Grid>
                  </div>
                ))}
            </InfiniteScroll>
          </>
        )}
      </div>
      //     ))}
      // </div>
    );
  }
}

export default ReportStudents;
