import A from './actionTypes';
const localStorageKey = 'SESOrgUI';

let localStorageState;

try {
  localStorageState = localStorage.getItem(localStorageKey)
    ? JSON.parse(localStorage.getItem(localStorageKey))
    : {};
} catch (e) {
  localStorageState = {};
}

const initialState = {
  ...{    
    showModal: false,
    showDrawer: true,
    modalContents: {},
  },
  // overwrite values from local state, if any
  ...localStorageState,
};

export default function root(state = initialState, action) {
  let newState = { ...state };
  const { type, payload} = action;

  switch ( type) {
    case A.SHOW_MODAL:
      newState = { ...state, showModal: true, modalContents: payload };
      break;
    case A.CLOSE_MODAL:
      newState = { ...state, showModal: false, modalContents: {} };
      break;

    case A.TOGGLE_DRAWER:
        newState = { ...state, showDrawer: !state.showDrawer };
        break;
  
    
    default:
    //newState = state;
  }

  localStorage.setItem(localStorageKey, JSON.stringify(newState));

  return newState;
}
