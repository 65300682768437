import React from "react";
import { Button, ClickAwayListener, Tooltip } from "@material-ui/core";
import { Help, Info } from "@material-ui/icons";
import WithTranslations from "../../WithTranslations";
import HelpText from "./HelpText";
import "./help-button.scss";

class HelpButton extends React.Component {
  state = {
    open: false,
  };
  componentDidMount = () => {
    this.props.fetchTranslations([this.props.translation_id]);
  };
  render() {
    const { open } = this.state;
    const { _t, iconType = "question", icon, translation_id } = this.props;
    return (
      <ClickAwayListener onClickAway={() => this.setState({ open: false })}>
        {/* <div> */}
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={() => this.setState({ open: false })}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          interactive
          placement="bottom-end"
          arrow
          classes={{ popper: "help-tip" }}
          title={
            <HelpText html={_t(translation_id)} className="help-tip-content" />
          }
        >
          <Button
            className="help-tip-button"
            onClick={() => this.setState({ open: true })}
          >
            {icon && icon}
            {!icon && iconType === "question" && <Help />}
            {!icon && iconType === "info" && <Info />}
          </Button>
        </Tooltip>
        {/* </div> */}
      </ClickAwayListener>
    );
  }
}

export default WithTranslations(HelpButton);
