import React from 'react'
import "./style.scss";
import {Button, Container, Typography} from '@material-ui/core'
import {Home} from '@material-ui/icons';
import WithTranslations from '../../WithTranslations';
import Spinner from '../../template/Spinner';
import {Link} from 'react-router-dom';
import AutoBreadcrumbs from '@bit/ses-education.ses-courses-shared-components.template.auto-breadcrumbs';
import { connect } from 'react-redux';

class About extends React.Component{

    componentDidMount(){
        this.props.fetchTranslations( ["About Us", "backToLogin"])
    }

    render(){
        const {_t, translationsLoaded, translationError, user } = this.props
        if( !translationsLoaded && !translationError ) return <Spinner/>
        return(
            <Container className="about">
                <AutoBreadcrumbs 
                    items={[
                        { icon: <Home /> , to: "/"},
                        { text: "About" },
                    ]}
                />
                <Typography color="primary" variant="h1"> {_t("About Us")}</Typography>
                <div className="skeleton text"></div>
                
                {
                    // show Login button only if user is not set
                !user && 
                <div className="buttons"><Button component={Link} to="/" variant="contained" color="primary">{_t("backToLogin")}</Button></div>
                }
            </Container> 
        )
    }
}

const stateToProps = state => ({
    user: state.auth.user
})

export default WithTranslations( connect( stateToProps )( About) )