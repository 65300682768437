import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { Assignment, Cancel } from "@material-ui/icons";
// import { Archive, Delete } from "@material-ui/icons";
import React from "react";
import "./style.scss";

class SelectUsersForm extends React.Component {
    state = {
        selected: []
    }


    onSelect = (selected) => {
        this.setState({selected})
    }

  render() {
    const { onAssign = console.debug, onClose, users } = this.props;
    const {selected} = this.state;
    if (!users) return "Loading...";
    if (!Array.isArray(users))
      return "Error loading users, please try refreshing the page";

    //   console.debug( users );

    const columns = [
      {
        field: "name",
        flex: 1,
        headerName: "Name",
        valueGetter: (params) => `${params.row.name}${params.row.last_name ? ` ${params.row.last_name}` : ""}`        
      },
    //   {
    //     field: "actions",
    //     flex: 1,
    //     headerName: "Actions",
    //     renderCell: (params) => (<ButtonGroup>
    //         {
    //             isOrganization &&
    //             <Button variant="contained" color="secondary"><Delete/> Remove</Button>
    //         }
    //         <Button variant="contained"><Archive/>Archive</Button>
    //     </ButtonGroup>)
    //   },
    ];

    return (
    <>
      <DataGrid
        autoHeight
        rows={users}
        columns={columns}
        onSelectionModelChange={this.onSelect}
        checkboxSelection
      />
      <div className="align-self-stretch flex grow-1 justify-content-space-evenly">
          <Button variant="contained" color="primary" disabled={!Boolean(selected.length > 0)} onClick={() => onAssign(selected)}><Assignment/> Assign</Button>
          <Button variant="contained" color="secondary" onClick={onClose}><Cancel/> Cancel</Button>
      </div>      
      </>
    );
  }
}

export default SelectUsersForm;
