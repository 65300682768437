import React from 'react';
// import { Editor } from '@tinymce/tinymce-react';
import { Tab, Button } from '@material-ui/core'
import {connect} from 'react-redux'
import { showMessage, deleteMessage } from '../../redux/notificationActions';

const tabid = "tab-"
const tabpanelid = "tab-field-"
const tab_data = [
  "Properties", "Content"
] 
const tinymceKey = process.env.REACT_APP_TINYMCE

const TabButton = props => (
    <Tab 
    label={props.label}
    id={`${tabid}${props.id}`}
    aria-controls={`${tabpanelid}${props.id}`}
    />
)

const TabPanel = props => {
  const {id, children, value = 0} = props
  
  return (
  <div
  role="tabpanel" 
  aria-labeledby={`${tabid}${id}`}
  id={`${tabpanelid}${id}`}
  hidden={value !== id}
  >{children}</div>
  )
}

class Test extends React.Component {
  handleEditorChange = (content, editor) => {
    console.log('Content was updated:', content);
  };

  state ={
    html: "",
    tabsIndex: 0,
    editingHtml: false,
    savingHtml: false
  }

  setTab = (ev, tabsIndex )=> { console.log(tabsIndex); this.setState({tabsIndex})}


  updateHtml = (html) => this.setState({html, editingHtml: false})


  render(){
    const {tabsIndex, editingHtml, savingHtml, html} = this.state
    const {onShowMessage, onDeleteMessage} = this.props;
    console.debug("Editing: ", editingHtml )
    
    return(
      <div className="flex row align-items-center">
        Alerts test: 
        <Button variant="contained" color="primary" onClick={() => onShowMessage("Error happened!", "error")}>Error</Button>
        <Button variant="contained" color="primary" onClick={() => onShowMessage("Success!", "success")}>Success</Button>
        <Button variant="contained" color="primary" onClick={() => onShowMessage("Warning!", "warning")}>Warning</Button>
        <Button variant="contained" color="primary" onClick={() => onShowMessage("Info text goes here", "info")}>Info</Button>
        <Button variant="contained" color="secondary" onClick={() => onShowMessage("This closes fast", "info", 500 )}>Fast autoclose</Button>
        <Button variant="contained" color="secondary" onClick={() => onShowMessage("This won't autoclose", "success", false )}>No autoclose</Button>
      </div>

      // editingHtml ?
      // <Editor  
      //   apiKey={tinymceKey}                            
      //   disabled={savingHtml}
      //   content={html}
      //   onSave={this.updateHtml}
      //   init ={
      //     {

      //       plugins: 
      //       [              
      //       "text-field image advlist autolink lists link image charmap print preview anchor",
      //       "searchreplace visualblocks code fullscreen",
      //       "insertdatetime table paste code help wordcount",
      //       ],
      //       toolbar:
      //       "undo redo | formatselect | bold italic backcolor | text-field "
      
      //     }
      //   }
      // /> :
      // <Container>
      //   <Button onClick={() => this.setState({editingHtml: true }) } variant="contained" color="primary">Edit</Button>
      //   <div dangerouslySetInnerHTML={{__html: html }}></div>
      // </Container>
    )
  }
  // render() {
  //   const {tabsIndex, editingHtml, savingHtml, html} = this.state
  //   return ( 
  //      <Loader data={this.props}>         
  //           { props => 
  //           <>
  //             <Container>
  //               <Tabs value={tabsIndex} onChange={this.setTab} centered>
  //                 {tab_data.map ( (t, ind) => (
  //                     <Tab label={t}
  //                     id={`${tabid}${ind}`}
  //                     aria-controls={`${tabpanelid}${ind}`}          
  //                     />
  //                 ) )}
                
  //               </Tabs>

  //               <div>
  //                   <TabPanel id={0} value={tabsIndex}>
  //                       {/* this is the main details (title, description) */}
  //                       <Form />
  //                   </TabPanel>
                                              
  //                   <TabPanel id={1} value={tabsIndex}>
  //                       {editingHtml ?
  //                       /* this is the HTML editor */
  //                       <Editor  
  //                       apiKey={tinymceKey}                            
  //                       disabled={savingHtml}
  //                       content={html}
  //                       onSave={this.updateHtml}
  //                       init ={
  //                         {

  //                           plugins: [
  //                           // "image advlist autolink lists link image charmap print preview anchor",
  //                           "searchreplace visualblocks code fullscreen",
  //                           // "insertdatetime table paste code help wordcount",
  //                           ]
  //                         }
  //                       }
  //                       />
  //                       :
  //                       <>
  //                           <div>
  //                               <Button variant="outlined" color="primary" onClick={()=> this.setState({editingHtml: true})}>
  //                                   Edit
  //                               </Button>
  //                           </div>
  //                           <div className="course-html-preview" dangerouslySetInnerHTML={{__html: html }}>
  //                           </div>
  //                       </>
  //                       }
                        
  //                   </TabPanel>                            
  //               </div>    
  //               </Container>
  //             </>          
  //           }
  //           </Loader>
      
        
  //   );
  // }
}
const mapDispatchToProps = (dispatch) =>{
  return {
      onShowMessage: (message, type, autoCloseIn) => dispatch(showMessage(message, type, autoCloseIn)),
      onDeleteMessage: (ind) => dispatch(deleteMessage(ind))
  }
}

export default connect(null, mapDispatchToProps)(Test);

