import React from 'react';
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from '@material-ui/core';
import {
  ChevronLeft,
  Mail,
  Dashboard,
  Settings,
  School,
  ViewList,
  People,
  AccountBalance,
  Assesment,
  MenuBook,
  Assessment,
} from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import './sidebar.scss';
import { connect } from 'react-redux';
import A from '../../../redux/actionTypes';
import { arraysIntersect } from '../../../utils/functions';

const menu = [
  { text: 'Dashboard', link: '/', icon: <Dashboard />, exact: true },
  { text: 'Students', link: '/students', icon: <People />, exact: false, credentials: ["organization"]   },
  { text: 'Teachers', link: '/teachers', icon: <People />, exact: false, credentials: ["organization"] },
  { text: 'Groups', link: '/groups', icon: <People />, exact: false, credentials: ["organization", "teacher"] },
  { text: 'Programs', link: '/programs', icon: <MenuBook />, exact: false },
  { text: 'Reports', link: '/reports', icon: <Assessment />, exact: false },
];

class Sidebar extends React.Component {
  render() {
    const { open = true, toggleDrawer, user = {} } = this.props;

    let {credentials} = user;
    credentials = credentials || []
    // isTeacher = credentials.includes("teacher");


    return (
      <Drawer
        variant='persistent'
        anchor='left'
        open={open}
        className='sidebar'
      >
        <div className='drawer-header'>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeft />
          </IconButton>
        </div>
        <Divider />
        <List>
          {menu.map((item, index) => {
            const { text, icon = <Mail />, link = '#', exact = false, credentials: itemCredentials } = item;

            if( Array.isArray(itemCredentials) && !arraysIntersect(credentials, itemCredentials) ) return null; 

            return (
              <ListItem
                className='drawer-menu-item'
                exact={exact}
                button
                key={text}
                component={NavLink}
                to={link}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            );
          })}
        </List>
        <Divider />
      </Drawer>
    );
  }
}

export default connect(
  (state) => ({ open: state.ui.showDrawer, user: state.auth.user }),
  (dispatch) => ({
    toggleDrawer: () => dispatch({ type: A.TOGGLE_DRAWER }),
  })
)(Sidebar);
