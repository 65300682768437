import React from "react";
import { Container, Typography } from "@material-ui/core";
import CustomCardList from "@bit/ses-education.ses-courses-shared-components.template.custom-card-list";

class Program extends React.Component {
  render() {
    const { program_id, title, description, courses, students } = this.props;
    const coursesParsed = Array.isArray(courses)
      ? courses.map((c) => ({
          ...c,
          description: c,
        }))
      : [];

    return (
      <div className="single-program">
        <Typography variant="h1" color="primary">
          {title}
        </Typography>
        <Typography variant="body1">{description}</Typography>
        {courses && courses[0] && (
          <>
            <Typography variant="h2">Course list</Typography>

            <ul className="program-course-list">
              {courses.map((c) => (
                <li>
                  <span>{c.course_code}</span>
                  <span>{c.title}</span>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    );
  }
}

export default Program;
