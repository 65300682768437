import React from 'react'
import { Route } from 'react-router'


const RouteComponent = props => {
  const {component: Component, ...other } = props
  return <Route {...other}  render={(p) => <Component {...p} />} />
  
}

export default RouteComponent