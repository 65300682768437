import React, { useState, useEffect } from 'react';
import {
  TextField,
  Input,
  InputAdornment,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import Page404 from '../template/Page404';
import { Email } from '@material-ui/icons';
import './login.scss';
import { Link } from 'react-router-dom';
import WithTranslations from '../WithTranslations';
import Spinner from '../template/Spinner';
import AuthService from '../../services/AuthService';
import ActionTypes from '../../redux/actionTypes';
import {connect} from 'react-redux'

const ResetPassword2 = ({ hash }) => {
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ name: '', last_name: '' });
  const [resolve, setResolve] = useState({ status: '', message: '' });

  useEffect(() => {
    // start off by showing loading process
    setLoading(true);
    let checkResult = null;
    // checks hash for validity and populates state with result
    async function probeHash() {
      checkResult = await AuthService.checkHash(hash);

      if (checkResult) {
        setLoading(false);
        setData({ name: checkResult.name, last_name: checkResult.last_name });
      } else {
        setError('404');
      }
    }

    probeHash();
  }, []);

  const validatePasswords = () => {
    setError(null);
    if (password.length < 6 || password2.length < 6) {
      setError('Password must be at least 6 charachters long');
    }
    if (password !== password2) {
      setError('Passwords must match');
    }
  };

  const submitPasswords = async () => {
    if (password === '' || password2 === '') {
      setError('Password required');
      return;
    }

    const result = await AuthService.updatePasswordAfterReset(password, hash);
    if (!result) {
      setResolve({
        status: 'fail',
        message: 'Password could not be reset, please try again',
      });
    } else {
      setResolve({
        status: 'success',
        message: 'Password was reset, you can now login',
      });
    }
  };

  return error === '404' ? (
    <Page404 />
  ) : !loading && data ? (
    <div id='reset-password-first'>
      <div className='reset-form'>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant='h6' color='primary'>
              {resolve.status !== ''
                ? resolve.message
                : `Hello ${data.name} ${data.last_name}, please enter your new password`}
            </Typography>
          </Grid>

          {resolve.status !== '' ? null : (
            <>
              <Grid item xs={12}>
                <TextField
                  id='password1'
                  label='Password'
                  type='password'
                  error={error ? true : false}
                  helperText={error}
                  onBlur={validatePasswords}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id='password2'
                  label='Confirm Password'
                  type='password'
                  error={error ? true : false}
                  helperText={error}
                  onBlur={validatePasswords}
                  // onMouseLeave={() => console.log('MOUSE LEFT')}
                  onChange={(event) => setPassword2(event.target.value)}
                />
              </Grid>
            </>
          )}

          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {resolve.status !== '' ? (
              <Button
                variant='contained'
                color='primary'
                style={{ marginRight: '15px' }}
                component={Link}
                to={resolve.status === 'success' ? '/' : '/reset-password'}
              >
                {resolve.status === 'success'
                  ? 'Go to login page'
                  : 'Go to reset page'}
              </Button>
            ) : (
              <>
                <Button
                  variant='contained'
                  color='primary'
                  style={{ marginRight: '15px' }}
                  component={Link}
                  to={'/'}
                >
                  Back to Login
                </Button>

                <Button
                  variant='contained'
                  color='secondary'
                  type='submit'
                  onClick={submitPasswords}
                >
                  Reset Password
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      email: '',
      linkSent: false,
      error: null,
    };
  }
  componentDidMount() {
    this.props.fetchTranslations([
      'resetPasswordText',
      'enterYourEmail',
      'backToLogin',
      'submit'
    ]);
  }

  async submitHandler() {
    const { email } = this.state;
    this.setState({ error: null });
    if (email === '') {
      this.setState({ error: 'Email required' });
      return;
    }
    const result = await AuthService.resetPassword(email);

    if( !result ){
      // handle error
      this.props.showErrorMessage( AuthService.error )
      return;
    }    
    
    this.setState({ linkSent: true });
    this.setState({ email: '' });

  }

  render() {
    const { email, linkSent, error } = this.state;

    const { translationError, translationsLoaded, _t } = this.props;
    if (!translationError && !translationsLoaded) return <Spinner />;
    const { hash } = this.props.match.params;
    // console.debug('translations:', this.props.translations);

    // TODO: if translationError - return translation error
    // if hash is passed via url - show resetPassword 2 screen
    return this.props.match.params && hash ? (
      <ResetPassword2 hash={hash} />
    ) : (
      <div id='reset-password-first'>
        <div className='reset-form'>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant='h6' color='primary'>
                {linkSent ? _t('resetPasswordText') : 'Please enter your email'}
              </Typography>
            </Grid>
            {linkSent ? null : (
              <Grid item xs={12}>
                <Input
                  id='input-with-icon-adornment'
                  autoComplete='username'
                  type='email'
                  name='email'
                  onChange={(event) =>
                    this.setState({ email: event.target.value })
                  }
                  value={email}
                  error={error}
                  startAdornment={
                    <InputAdornment position='start'>
                      <Email color='primary' />
                    </InputAdornment>
                  }
                />
              </Grid>
            )}

            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button
                variant='contained'
                color='primary'
                style={{ marginRight: '15px' }}
                component={Link}
                to={'/'}
              >
                {_t('backToLogin')}
              </Button>
              {linkSent ? null : (
                <Button
                  variant='contained'
                  color='secondary'
                  type='submit'
                  onClick={() => this.submitHandler()}
                >
                  {_t('submit')}
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

const dispatchToProps = dispatch => ({
  showErrorMessage : message => dispatch({type: ActionTypes.SHOW_MESSAGE, payload:{message, type: "error"}}), 
})
export default connect( null, dispatchToProps )( WithTranslations( ResetPassword ) );
