import React from "react";
import "./style.scss";
import { DataGrid } from "@material-ui/data-grid";
import { LinearProgress } from "@material-ui/core";
import { Email, EmojiEvents, Person } from "@material-ui/icons";
import {objectsAreEqual} from '../../../utils/functions'
import Spinner from "../../template/Spinner";

const columns = [
  { field: "id", hide: true },
  { field: "course_id", headerName: "Course ID", width: 160, hide: true },
  { field: "title", headerName: "Course", width: 250 },
  { field: "course_progress", headerName: "Progress", width: 150, type: 'number' },
  { field: "course_grade", headerName: "Grade", width: 150, type: 'number' },
];

const Grade = props => <span className="grade"><EmojiEvents/>{props.value}</span>

const Progress = props => <span className="linear-progress-bar">
    {props.showValue && <span className="value">{props.value}</span> }
    <LinearProgress className="progress" value={props.value} valueBuffer={100} variant="determinate" />
  </span>


const CourseProgress = (props) => {
  
  let { items, columns } = props;

  if( !items || !columns ) return <Spinner />

  return (
    <div className="course-progress">
      {/* <div className="title">Progress</div> */}
      <div className="data-grid-container">
        <DataGrid
          autoHeight
          pageSize={10}
          rows={items}
          columns={columns}
        />
      </div>
    </div>
  );
};

class UsersReportItem extends React.Component {

  state = {    
    parsedProgress: null,
    parsedColumns:  {...columns }
  }

  componentDidMount(){
    this.prepareData();
  }

  componentDidUpdate(prevProps){
    const { courses, assigned_courses } = this.props 
    const { courses: prevCourses, assigned_courses: prevAssignedCourses } = prevProps
    if( !objectsAreEqual (courses, prevCourses ) || !objectsAreEqual(assigned_courses, prevAssignedCourses) )
      this.prepareData();
    
  }

  prepareData(){
    let { course_progress } = this.props

    course_progress = Array.isArray(course_progress) ? course_progress : []

    let parsedProgress = [];

    // TODO: check if it's an array

    // find longest experiment_progress array 
    let longestExperimentSequence = 0, i;
    for(i=0; i< course_progress.length; i++){
      const {progress = [] } = course_progress[i]
      if( Array.isArray(progress)  && progress.length > longestExperimentSequence ) 
        longestExperimentSequence = progress.length;
      // console.debug( "current length", progress.length, "longestExperimentSequence", longestExperimentSequence, course_progress, )
    }

    

    parsedProgress = course_progress.map( ( course ) => {
      let {course_id, progress, ...courseProgressData } = course       

      progress = progress || [];
      
      // prepare experiment progress values for table
      let experimentProgressData = progress.reduce( (res, exp, ind ) => {
        return {
          ...res, 
          // name it 'expN`, it will be the index in all experiments in the table
          [`exp${ind + 1}`] : exp
        }
      } 
      , {});

      return {
        course_id, 
        ...experimentProgressData,
        ...courseProgressData
      }; 
    })

    // prepare columns
    let experimentColumns = []
    for(i=0; i<longestExperimentSequence; i++){
      experimentColumns = [...experimentColumns, {
        field: `exp${i+1}`,
        width: 60,
        headerName: `#${i+1}`,
        sortable: false,  // remove sorting
        disableColumnMenu: true, // hide menu
        renderCell: (params) => {
          // console.debug("valueFormatter params:", params)
          const {experiment_grade : grade, experiment_progress: progress} = params.value || {};
          
          return ! params.value ? <></> : (  progress < 100 ? <Progress value={progress || 0} showValue/> : <Grade value={grade || 0} />) 
          
        },
      }]
    }

    // first three coluns are 2 hidden ids and title
    const [column1, column2, column3, ...otherColumns] = columns
    let parsedColumns = [ column1, column2, column3,  ...experimentColumns, ...otherColumns]

    this.setState({ parsedProgress, parsedColumns })

    
  }

  render() {
    const { id, name, last_name, email, progress, courses } = this.props;
    const {parsedProgress, parsedColumns } = this.state
    
    return (
      <div className="item">
        <div className="personal-data"><span><Person/>{`${name} ${last_name}`}</span><span><Email/>{email}</span></div>
        {parsedProgress && (
          <CourseProgress
            columns={parsedColumns}
            items={parsedProgress.map((course) => ({
              ...course,
              id: course.course_id,
            }))}
          />
        )}
      </div>
    );
  }
}

export default UsersReportItem;