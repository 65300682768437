import React from "react";
import { Container, Grid, Typography, Button, Icon } from "@material-ui/core";
import { connect } from "react-redux";
import UserService from "../../services/UserService";
import Spinner from "../template/Spinner";
import { strFormat } from "../../utils/functions";
import { Link } from "react-router-dom";
import { Assessment, Group, MenuBook } from "@material-ui/icons";
import WithTranslations from "../WithTranslations";
import StudentsImg from "./images/students.jpg";
import ProgramsImg from "./images/programs.jpg";
import ReportsImg from "./images/reports.jpg";
import "./style.scss";

/**
 * Creates a url() text, compatible with background-image attribute
 * @param {*} url
 * @returns
 */
const urlImg = (url) => `url(${url})`;

const IconCard = (props) => (
  <div className="main-icons-item card bubble">
    <Link
      style={{ backgroundImage: urlImg(props.image) }}
      className="item-image"
      to={props.link}
    ></Link>
    <div className="item-content">{props.children}</div>
  </div>
);

class Dashboard extends React.Component {
  state = {
    translations: {
      dashboardUsersText: "You have %num% students",
      errorLoadingData: "Error loading data. Please try reloading the page.",
      ManageStudents: "Manage your students",
      AddStudents: "Let's add some students",
      Students: "Students",
      reportsButtonText: "See data reports",
      Programs: "Programs",
      pensumButtonText: "Review programs",
    },
    students: null,
    isteacher: null,
    error: null,
  };

  // _t = (id) => this.state.translations[id] || id;

  componentDidMount() {
    this.fetchUsersData();
    this.props.fetchTranslations([
      "dashboardUsersText",
      "errorLoadingData",
      "ManageStudents",
      "AddStudents",
      "Students",
      "reportsButtonText",
      "Programs",
      "Student groups",
      "pensumButtonText",
    ]);

    this.setUpUser();
  }

  componentDidUpdate(prevProps){
    if(prevProps.user !== this.props.user ) this.setUpUser();
  }

  setUpUser(){
    const {user} = this.props;
    const isTeacher = user && Array.isArray(user.credentials) ? user.credentials.includes("teacher") : null;
    this.setState({isTeacher})
  }

  async fetchUsersData() {
    const users = await UserService.getUsers();

    if (!users) {
      this.setState({ error: UserService.error });
      return;
    }

    console.debug("users", users);
    const students = users.filter((u) => u.credentials && u.credentials.includes("student"));

    this.setState({ students });
  }

  render() {
    const { students, isTeacher, error } = this.state;
    const { user } = this.props;

    const { translationError, translationsLoaded, _t } = this.props;
    if (!translationError && !translationsLoaded) return <Spinner />;

    // const _t = this._t.bind(this);
    console.debug(students)
    const organization = user ? user.organizations[0] : {};
    
    const studentsButtonText =
      students && students.length > 0
        ? _t("ManageStudents")
        : _t("AddStudents");
    return (
      <Container className="dashboard">
        <Typography variant="h1" color="primary">
          {(organization && organization.organization_title) || "Organization"}
        </Typography>
        <Grid container className="main-icons" spacing={4}>
          <Grid item xs={12} md={6} className="main-icons-grid">
            {
              isTeacher ? 
            <IconCard image={StudentsImg} link={"/groups"}>
              <Typography variant="h2" color="primary"> {_t("Student groups")}</Typography>              
              <div>
                <Button
                  to={"/groups"}
                  component={Link}
                  variant="contained"
                  color="primary"
                >
                  <Group /> {studentsButtonText}
                </Button>
              </div>
            </IconCard> :
            <IconCard image={StudentsImg} link={"/students"}>
              <Typography variant="h2" color="primary"> {_t("Students")}</Typography>
              {students === null ? (
                <Spinner />
              ) : students === false ? (
                _t("errorLoadingData")
              ) : (
                <Typography>
                  {strFormat(_t("dashboardUsersText"), {
                    "%num%": students.filter(c=>c.status.status_id === 'active').length.toString(),
                  })}
                </Typography>
              )}
              <div>
                <Button
                  to={"/students"}
                  component={Link}
                  variant="contained"
                  color="primary"
                >
                  <Group /> {studentsButtonText}
                </Button>
              </div>
            </IconCard>
            }
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <IconCard image={ProgramsImg} link="/programs">
                  <Typography variant="h2" color="primary"> {_t("Programs")}</Typography>
                  <div>
                    <Button
                      to={"/programs"}
                      component={Link}
                      variant="contained"
                      color="primary"
                    >
                      <MenuBook /> {_t("pensumButtonText")}
                    </Button>
                  </div>
                </IconCard>
              </Grid>
              <Grid item xs={12}>
                <IconCard link="/reports" image={ReportsImg}>
                  <Typography variant="h2" color="primary"> {_t("Reports")}</Typography>
                  <div>
                    <Button
                      to={"/reports"}
                      component={Link}
                      variant="contained"
                      color="primary"
                    >
                      <Assessment /> {_t("reportsButtonText")}
                    </Button>
                  </div>
                </IconCard>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const stateToProps = (state) => ({ user: state.auth.user });

export default WithTranslations(connect(stateToProps)(Dashboard));
