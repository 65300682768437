import React from "react";
import "./style.scss";
import {Container} from "@material-ui/core";
import { Home} from '@material-ui/icons';
import ProgramService from "../../../services/ProgramService";
import AutoBreadcrumbs from "../../template/CustomBreadcrumbs";
import ProgramList from "./program-list";
import Program from "./program";

class Programs extends React.Component {
  state = {
    programs: null,
    error: null,
    current_program: null
  };

  componentDidMount() {
    this.fetchPrograms();
  }

  componentDidUpdate(prevProps){
    const {program_id} = this.props.match.params;
    const {program_id: prev_program_id} = prevProps.match.params;
    // update state if program id has changed
    if( program_id !== prev_program_id ) this.fetchPrograms();
  }

  async fetchPrograms() {
    const programs = await ProgramService.getPrograms();
    if (!programs) {
      this.setState({ error: ProgramService.error, programs: false });
      return;
    }

    // check if route contains program id, and if so - find corresponding program
    let {program_id} = this.props.match.params;
    // cast to integer for correct comparison
    program_id = parseInt(program_id)
    const current_program =  program_id ? programs.find( p => p.program_id === program_id ) : null;

    this.setState({ programs, current_program });
  }

  render() {
    const { programs, current_program, error } = this.state;
    let breadcrumbs = current_program ? 
    [{ icon: <Home />, to: "/" }, { text: "Programs", to:"/programs" }, { text: current_program.title }] :
    [{ icon: <Home />, to: "/" }, { text: "Programs" }];
    const itemProps = {
      sm: 12,
      md: 12,
      lg: 12,
      xl: 6
    }
    
    const backLink = current_program ? "/programs" : null;
    return (
      <Container className="programs">
        <AutoBreadcrumbs items={breadcrumbs} {...{backLink}} />
        {current_program ? 
        <Program {...current_program} /> :
        <ProgramList 
        programs={programs}
        {...{
          programs, 
          error,
          // itemProps          
        }} 
        itemProps={{ md: 12, lg: 12 }}
        />}
      </Container>
    );
  }
}

export default Programs;
