import { Button, Typography } from "@material-ui/core";
import React from "react";
import ModalWindow from "@bit/ses-education.ses-courses-shared-components.modal-window";
import PropTypes from "prop-types";
// import ModalWindow from '../modal-window';

// class ConfirmDialog extends React.Component {
export default function ConfirmDialog(props){
  

  // render() {
    const {
      prompt,
      onConfirm,
      onClose,
      cancelText = "Cancel",
      confirmText = "Yes",
      promptCssClass,
      ...other
    } = props;
    return (
      <ModalWindow
        formClassName="confirm-dialog stretch"
        {...other}
        onClose={onClose}
        buttons={[
          <Button variant="contained" color="primary" onClick={onConfirm}>
            {confirmText}
          </Button>,
          <Button variant="contained" color="secondary" onClick={onClose}>
            {cancelText}
          </Button>,
        ]}
      >
        <Typography className={promptCssClass}>{prompt}</Typography>
      </ModalWindow>
    );
  // }
}

ConfirmDialog.propTypes = {
  /** function to call on confirm */
  onConfirm: PropTypes.func,
  /** function to call on cancel */
  onCancel: PropTypes.func,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  prompt: PropTypes.string,
};

ConfirmDialog.defaultProps = {
  cancelText: "Cancel",
  confirmText: "Yes",
  promptCssClass: "flex column align-items-center"
};

// export default ConfirmDialog;
