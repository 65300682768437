import React, { useState } from 'react';
import { BrowserRouter, Link } from 'react-router-dom';
import { Switch } from 'react-router';
import RouteComponent from './RouteComponent';
import './style.scss';
// import Notification from '../template/Notification';
import routes from '../../data/routes';
import Sidebar from '../template/Sidebar';
import Navbar from '../template/Navbar';
import { connect } from 'react-redux';
import { Menu as MenuIcon, Person as UserIcon } from '@material-ui/icons';
import { Menu, MenuItem } from '@material-ui/core';
import { showMessage } from '../../redux/notificationActions';
import A from '../../redux/actionTypes';
import AuthService from '../../services/AuthService';
import { arraysIntersect } from '../../utils/functions';


const {REACT_APP_BASE_URL} = process.env;

class AuthorizedMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      menuTarget: null,
    };
  }

  render() {
    const { menuTarget } = this.state;
    const { location, showDrawer, toggleDrawer, user, onShowMessage } =
      this.props;

    const { name = '', last_name = '', credentials } = user;

    const RightComponent = () => {
      const show = (ev) => {
        // this.setState({ menuTarget: ev.currentTarget }, () => console.debug("show:", this.state.menuTarget));
        setMenuTarget(ev.currentTarget);
      };

      const hide = (ev) => {
        // this.setState({ menuTarget: null });
        setMenuTarget(null);
      };

      const logout = async (ev) => {
        // this.setState({ menuTarget: null });
        setMenuTarget(null);
        const result = await AuthService.logout();

        if (!result) {
          onShowMessage(`Failed to logout`, 'error');
        }
      };

      const [menuTarget, setMenuTarget] = useState(null);

      return (
        <div className='top-right'>
          <div className='user-icon link' onClick={show}>
            <UserIcon />
            {`${name}${last_name ? ` ${last_name}` :  ""}`}
          </div>
          <Menu
            id='simple-menu'
            anchorEl={menuTarget}
            keepMounted
            open={Boolean(menuTarget)}
            onClose={hide}
          >
            <MenuItem component={Link} to={'/profile'} onClick={hide}>
              Profile
            </MenuItem>
            {/* <MenuItem onClick={hide}>My account</MenuItem> */}
            <MenuItem onClick={logout}>Logout</MenuItem>
          </Menu>
        </div>
      );
    };

    const navbarProps = {
      location,
      LeftComponent: (
        <div className='top-left'>
          <div className='menu-button'>
            <MenuIcon onClick={toggleDrawer} />
          </div>
        </div>
      ),
      RightComponent: <RightComponent />,
    };

    return (
      <BrowserRouter basename={REACT_APP_BASE_URL}>
        <Navbar {...navbarProps} />
        <Sidebar />
        <div className={`main ${showDrawer ? 'drawer-open' : ''}`}>
          <Switch>
            {
              // filter only routes available to current user
            routes.filter( r => 
              // if route crdentials are defined and user credentials are defined...
              Array.isArray(r.credentials) && Array.isArray(credentials) ? 
              // return whetherthe two arrays intersect
              arraysIntersect(r.credentials, user.credentials) 
              // otherwise, everything's available
              : true ) 
            // return routes
            .map((route, ind) => (
              <RouteComponent {...{ ...route, key: `route-${ind}` }} />
            ))}
          </Switch>
        </div>        
      </BrowserRouter>
    );
  }
}
const stateToProps = (state) => {
  const { showDrawer } = state.ui;
  // const { user } = state.auth;


  // return { showDrawer, user };
  return { showDrawer};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch(showMessage(message, type)),
    toggleDrawer: () => dispatch({ type: A.TOGGLE_DRAWER }),
  };
};

export default connect(stateToProps, mapDispatchToProps)(AuthorizedMain);
