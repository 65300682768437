import React from "react";
import Spinner from "../../template/Spinner";
import Error from "../../template/Error";
import CustomCardList from "@bit/ses-education.ses-courses-shared-components.template.custom-card-list";
import ColumnedList from  "./c-list" //"@bit/ses-education.ses-courses-shared-components.template.custom-card-list/columned-list";

import { Typography } from "@material-ui/core";

class ProgramList extends React.Component {
  render() {
    const { programs, error } = this.props;

    return (
      <>
        <Typography variant="h1" color="primary">
          Programs
        </Typography>
        {programs === null ? (
          <Spinner />
        ) : programs === false ? (
          <Error error={error} />
        ) : (
          <CustomCardList
            items={programs.map((p) => ({
              ...p,
              link: `/programs/${p.program_id}`,
              description: <div><p>{p.description}</p><ColumnedList items={p.courses || [] }/></div>
            }))}
            itemProps={{ md: 12, lg: 12 }}
            cardImageProps={{style: {width: "auto", height: "auto"} }}
            // {...{ organization }}
          />
          // programs.map((p) => (
          //   <div className="program-card">
          //     <Typography variant="h2" color="primary">
          //       {p.title}
          //     </Typography>
          //     <div className="description">{p.description}</div>
          //     {p.courses && (
          //       <div className="course-list-wrapper">
          //         <h3 color="primary">Course list</h3>
          //         <ul className="course-list">
          //           {p.courses.map((c) => (
          //             <li>{c && c.title}</li>
          //           ))}
          //         </ul>
          //       </div>
          //     )}
          //   </div>
          // ))
        )}
      </>
    );
  }
}

export default ProgramList;
