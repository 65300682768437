import AuthService from '../services/AuthService';
import { showMessage } from './notificationActions';

import store from './store';

let tokenUpdateTimer;

const renewTokenIn = process.env.REACT_APP_TOKEN_RENEW || 1000 * 60 * 28; // ms. renew token in 28 minutes
const usageThreshold = process.env.REACT_APP_TOKEN_RENEW_THRESHOLD ||1000 * 60 * 10; // renew only if used in the last 10 minutes

class Actions {
  static async onTokenUpdate(tokenTimestamp) {
    //( renewIn, usageTreshold ){

    const now = Date.now();

    // renew in time, that equals *default renew time* minus *the time already spent since tokenTimestamp*
    // this is needed for when a page is reloaded and renew token timer has to be set again.
    const tokenUpdateTimespan = renewTokenIn - (now - tokenTimestamp);

    if (tokenUpdateTimespan > 0) {
      this.clearTokenUpdateTimer();
      tokenUpdateTimer = setTimeout(async () => {
        const { lastUsed, user } = store.getState().auth;
        const now = Date.now();
        this.counter += 1;
        const counter = this.counter;

        // if logged in...
        if (user) {
          // if has been used recently, renew the token
          if (now - lastUsed <= usageThreshold) {
            await AuthService.renew();
          } else {
            console.debug("logging out...")
            // else - log out
            await AuthService.logout();
            // show message about expired session
            console.debug("showing log out message")
            store.dispatch( showMessage("Your session has expired", "warning") )
          }
        } else {
          // do nothing, the user isn't logged in yet
          // (this should not happen at all, actually)
        }
      }, tokenUpdateTimespan);
    } else {
      await AuthService.renew();
    }
  }

  static clearTokenUpdateTimer() {
    clearTimeout(tokenUpdateTimer);
  }
}

Actions.isFetchingToken = false;
Actions.counter = 0;

export default Actions;
