import { createStore, applyMiddleware, compose, combineReducers } from "redux"
import thunk from 'redux-thunk'
import auth  from './authReducers'
import ui from "./uiReducers"
import {reducers} from '@bit/ses-education.ses-courses-shared-components.notifications'

const store  = createStore( combineReducers({
  auth, 
  ui,
  reducers,
}), compose(applyMiddleware(thunk)))
export default store; 