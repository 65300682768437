import { Button, ButtonGroup } from "@material-ui/core";
import React from "react";
import { showMessage } from "../../../redux/notificationActions";
import store from "../../../redux/store";
import ProgressService from "../../../services/ProgressService";
import Error from "../../template/Error";
import ConfirmDialog from "../../template/ConfirmDialog";
import experiment_pages from "../../../data/experiment_pages.json";
import AdminTable from "@bit/ses-education.ses-courses-shared-components.admin-table";
import "./style.scss";
import Spinner from "../../template/Spinner";
import AsyncButton from "../../template/AsyncButton";

const msg = (text, level) => store.dispatch(showMessage(text, level));

const PagesProgressDisplay = ({ pages, experiment_progress }) => (
  <ul className="pages-progress-display">
    {
      // cycle through experiment pages' ids
      Object.keys(experiment_pages).map((p) => {
        // const e_page = experiment_pages[p]

        console.debug("ppd: pages: ", pages, "\npage ID:", p);
        // if pages is an array...
        const item = Array.isArray(pages)
          ? // find page with the same page slug as current page id
            pages.find((pp) => pp.page_slug === p) || {}
          : {};
        return <li className={`${item.status || "nope"} `}></li>;
      })
    }
    <li className="text">{experiment_progress || 0}%</li>
  </ul>
);

class GroupStudentCourse extends React.Component {
  state = {
    progress: null,
    error: null,
    confirmResetCourse: null,
    confirmResetPage: null,
    confirmMessage: null,
    onConfirm: null,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { user_id, course } = this.props;
    if (!user_id || !course || !course.course_id) {
      console.error(
        "GroupStudentCourse.fetchData error: user_id, course or course.course_id not stated"
      );
      this.setState({ error: "Error in passed data" });
    }
    try {
      const progress = await ProgressService.getUserCourseProgress(
        user_id,
        course.course_id
      );

      this.setState({ progress });
    } catch (e) {
      this.setState({ progress: false, error: e.message });
    }
  };

  confirmCourseReset = () => {};

  resetCourseProgress = async () => {
    try {
      const { user_id, course } = this.props;
      const { course_id } = course || {};

      if (!user_id || !course) {
        console.debug(
          "resetCourseProgress no user_id or course_id:",
          user_id,
          course_id
        );
        msg(
          "Could not reset course progress due to wrong data received",
          "error"
        );
        return;
      }

      await ProgressService.resetCourseProgress(user_id, course_id);
      msg("Course progress successfully reset");
      this.fetchData();
    } catch (e) {
      msg(e.message, "error");
    }
  };

  resetExperimentProgress = async (experiment_id) => {
    try {
      const { user_id, course } = this.props;

      if (!user_id) {
        console.debug("resetExperimentProgress no user_id:", user_id);
        msg(
          "Could not reset experiment progress due to wrong data received",
          "error"
        );
        return;
      }

      await ProgressService.resetExperimentProgress(user_id, experiment_id);
      msg("Experiment progress successfully reset");
      this.fetchData();
    } catch (e) {
      msg(e.message, "error");
    }
  };

  resetPageProgress = async (experiment_id, page) => {
    try {
      const { user_id } = this.props;

      if (!user_id) {
        console.debug("resetPageProgress no user_id:", user_id);
        msg(
          "Could not reset page progress due to wrong data received",
          "error"
        );
        return;
      }

      await ProgressService.resetExperimentPageProgress(
        user_id,
        experiment_id,
        page
      );
      msg("Page progress successfully reset");
      this.fetchData();
    } catch (e) {
      msg(e.message, "error");
    }
  };

  render() {
    console.debug("group student course props", this.props);
    const { program_id, course, course_progress } = this.props;

    const {
      progress,
      error,
      confirmResetCourse,
      confirmResetPage,
      confirmMessage,
      onConfirm,
    } = this.state;

    if (error) return <Error {...{ error }} />;

    console.debug("course_progress:", progress);

    const rows =
      progress && Array.isArray(progress.experiment_progress)
        ? progress.experiment_progress.map((pp, index) => ({
            ...pp,
            index,
            id: pp.experiment_id,
          }))
        : null;
    const columns = [
      { field: "id", hide: true },
      {
        field: "index",
        headerName: "#",
        renderCell: ({ value }) => <span>{value + 1}</span>,
        // flex: "0 0",
      },
      {
        field: "title",
        headerName: "Experiment",
        flex: 1,
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
      },
      {
        field: "progress",
        headerName: "Progress",
        flex: 1,
        renderCell: ({ row }) => {
          const { pages, experiment_progress } = row;
          console.debug("pages:", pages);
          return <PagesProgressDisplay {...{ pages, experiment_progress }} />;
        },
      },
      {
        field: "experiment_grade",
        headerName: "Score",
        flex: 1,
        // renderCell: ({ value }) => (value > 0 ? value : "not started"),
      },

      {
        field: "actions",
        flex: 2,
        headerName: "Actions",
        renderCell: ({ row }) => {
          console.debug("course progress:", course);
          console.debug("row.experiment_id:", row.experiment_id);
          const canReset =
            ["open", "done"].includes(row.status) &&
            // row.experiment_progress > 0 &&
            progress.current_experiment_id &&
            progress.current_experiment_id === parseInt(row.experiment_id);
          const canResetChapter = progress && Array.isArray(progress.experiment_progress) && 
          progress.experiment_progress.find(pp => pp.experiment_id === progress.current_experiment_id)
          .experiment_progress > 0;
          const canResetExperiment = progress && Array.isArray(progress.experiment_progress) && progress.experiment_progress.length > 0 &&
          (progress.experiment_progress.find((exp)=>exp.experiment_id === progress.current_experiment_id)||{}).experiment_progress > 0;
          const prevExperimentIndex = progress && Array.isArray(progress.experiment_progress) && progress.experiment_progress.length > 0 &&
          progress.experiment_progress.findIndex((exp)=>exp.experiment_id === progress.current_experiment_id) -1;
          const prevExperiment = prevExperimentIndex > -1 && progress && Array.isArray(progress.experiment_progress) && progress.experiment_progress[prevExperimentIndex];
          // console.debug("row:", row);
          return <>
            {canReset && canResetExperiment && (
              <ButtonGroup>
                <AsyncButton
                  size="small"
                  color="secondary"
                  variant="contained"
                  onClick={async () =>
                    new Promise((resolve) => {
                      this.setState({
                        confirmMessage: `Are you sure you want to reset progress for this experiment: ${row.title} ?`,
                        onConfirm: async () => {
                          this.setState({
                            confirmMessage: null,
                            onConfirm: null,
                          });
                          await this.resetExperimentProgress(row.experiment_id);
                          resolve(true);
                        },
                      })
                    })
                  }
                >
                  Reset experiment
                </AsyncButton>
                <AsyncButton
                  size="small"
                  color="secondary"
                  variant="contained"
                  onClick={async () =>
                    new Promise((resolve) => {
                      this.setState({
                        confirmMessage: `Are you sure you want to reset page "${row.current_page}" progress in this experiment: ${row.title} ?`,
                        onConfirm: async () => {
                          this.setState({
                            confirmMessage: null,
                            onConfirm: null,
                          });
                          await this.resetPageProgress(
                            row.experiment_id,
                            row.current_page
                          )
                          resolve(true);                      
                        },
                      })
                    })
                  }
                >
                  Reset last page
                </AsyncButton>
              </ButtonGroup>
            )}
            {prevExperiment && prevExperiment.experiment_progress > 0 && !canResetExperiment &&
             prevExperiment.experiment_id === parseInt(row.experiment_id) && (
              <ButtonGroup>
                <AsyncButton
                  size="small"
                  color="secondary"
                  variant="contained"
                  onClick={async () =>
                    new Promise((resolve) => {
                      this.setState({
                        confirmMessage: `Are you sure you want to reset page summary progress in this experiment: ${row.title} ?`,
                        onConfirm: async () => {
                          this.setState({
                            confirmMessage: null,
                            onConfirm: null,
                          });
                          await this.resetExperimentProgress(progress.current_experiment_id);
                          resolve(true);
                        },
                      })
                    })
                  }
                >
                  Reset last page
                </AsyncButton>
              </ButtonGroup>
            )}
          </>
        },
      },
    ];

    console.debug("GroupStudentCourse course_progress:", course_progress);
    console.debug("GroupStudentCourse progress:", progress);
    return (
      <div className="group-student-course">
        <h2>
          {course.course_code} : {course.title}
        </h2>
        {progress && progress.progress_percent > 0 && (
          <>
            <span class="course-progress">
              {progress.progress_percent < 100 ? (
                <>Progress: {progress.progress_percent || 0}%</>
              ) : (
                <>Score: {progress.total_score || 0}</>
              )}
              <AsyncButton
                variant="contained"
                color="secondary"
                size="small"
                // onClick={() => this.setState({ confirmResetCourse: true })}
                onClick={async () =>
                  new Promise((resolve) => {
                    this.setState({
                      confirmMessage:
                        "Are you sure you want to completely reset this course progress?",
                      onConfirm: async () => {
                        this.setState({
                          confirmMessage: null,
                          onConfirm: null,
                        });
                        await this.resetCourseProgress();
                        resolve(true);
                      },
                    })
                  })
                }
              >
                Reset
              </AsyncButton>
            </span>
          </>
        )}
        {/* <ButtonGroup>
          
        </ButtonGroup> */}

        {!progress && <Spinner />}
        {rows && <AdminTable autoHeight {...{ columns, rows }} />}

        {/* {JSON.stringify(progress)} */}
        {/* {JSON.stringify(current_user)} */}

        <ConfirmDialog
          open={confirmResetCourse}
          header="Resetting course progress"
          prompt="Are you sure you want to reset course progress completely?"
          onConfirm={() => {
            this.setState({ confirmResetCourse: false });
            this.resetCourseProgress();
          }}
          onClose={() => this.setState({ confirmResetCourse: false })}
        />
        <ConfirmDialog
          open={confirmMessage && onConfirm}
          header="Reset"
          prompt={confirmMessage}
          onConfirm={onConfirm}
          onClose={() =>
            this.setState({ confirmMessage: null, onConfirm: null })
          }
        />
      </div>
    );
  }
}

export default GroupStudentCourse;
