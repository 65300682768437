import { CircularProgress, Container } from '@material-ui/core';
import React from 'react';

const Spinner = (props) => {
  return (
    <Container className="spinner-container">
      <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
        <CircularProgress color='primary' />
      </div>
    </Container>
  );
};

export default Spinner;
