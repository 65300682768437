import React from "react";
// import {DataGrid } from "@material-ui/data-grid";
// import AutoBreadcrumbs from "@bit/ses-education.ses-courses-shared-components.template.auto-breadcrumbs";
import { Button, ButtonGroup, Tab, Tabs, Typography, Tooltip, Zoom } from "@material-ui/core";
import {
  // Archive,
  Cancel,
  // Add,
  // Block,
  Delete,
  // Edit,
  GroupAdd,
  // Home,
  Remove,
  Visibility,
} from "@material-ui/icons";
import "./style.scss";
import { DataGrid } from "@material-ui/data-grid";
// import Spinner from '../../template/Spinner';
import ModalWindow from "@bit/ses-education.ses-courses-shared-components.modal-window";
import UserService from "../../../services/UserService";
import SelectUsersForm from "./select-users-form";
import SwipeableViews from "react-swipeable-views";
import { Link } from "react-router-dom";
import ConfirmDialog from "../../template/ConfirmDialog";
import OrganizationService from "../../../services/OrganizationService";
import ModalAddStudents from "../../template/ModalAddStudents";
import AsyncButton from "../../template/AsyncButton";
import TeacherForm from "../Teachers/teacher-form";
import { strFormat } from "../../../utils/functions";
import { statusDescription } from "../../../data/statusDescription";

class Group extends React.Component {
  state = {
    rows: null,
    error: null,
    editing: null,
    deleting: null,
    showForm: false,
    showFormTeacher: false,
    // these two are used to dispay and react to confirmation dialog
    /// onConfirm is the function to call
    onConfirm: null,
    confirmationText: null,

    showAssignForm: false,
    showAssignTeachersForm: false,
    studentsNotInGroup: null,
    teachersNotInGroup: null,
    selectedUsers: [],
    selectedTeachers: [],
    selectedAssignUsers: [],
    tabSelector: 0,
    usersInGroup: [],
    allUsers: [],
    sortModel: [{ field: "status", sort:"asc" },{ field: "name", sort:"asc" }],
  };
  componentDidMount() {
    // this.prepareRows();
    this.fetchUsers();
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) this.prepareRows();
    if (this.props.students !== prevProps.students|| this.props.teachers!==prevProps.teachers)
      this.fetchUsers();
  }

  async fetchUsers() {
    try {
      console.log("fetchUsers");
      let users = await UserService.getUsers();

      let { teachers, students } = this.props;
      teachers = Array.isArray(teachers) ? teachers : [];
      students = Array.isArray(students) ? students : [];

      const studentIds = students.map((s) => s.id);
      const teacherIds = teachers.map((s) => s.id);

      if (Array.isArray(users)) {
        // select STUDENT users that are not currently in group
        const studentsNotInGroup = users
          .filter(
            (user) =>
              user.credentials &&
              user.credentials.includes("student") &&
              !studentIds.includes(user.id)
          )
          .map((s) => ({
            ...s,
            id: s.id,
            full_name: `${s.name}${s.last_name ? ` ${s.last_name}` : ""}`,
          }));

          const studentsInGroup = users
          .filter(
            (user) =>
              user.credentials &&
              user.credentials.includes("student") &&
              studentIds.includes(user.id)
          ).map((u) => ({
            ...u,
            id: u.id,
            name:u.name,
            status:u.status.status_title,
            progress: [
               u.courses_total ? (
                <Tooltip
                  TransitionComponent={Zoom}
                  title={this.populateProgressDetails(u.assigned_courses || [] , u.course_progress || [])}
                  arrow
                >
                  <Typography style={{ cursor: "default" }}>
                    {(u.courses_done || 0) + "/" + u.courses_total}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography style={{ cursor: "default" }}>{"N/A"}</Typography>
              ),
            ],
          }));
        /// all students users
        const allUsers = [...studentsNotInGroup, ...studentsInGroup]; 
        // select TEACHER users that are not currently in group
        const teachersNotInGroup = users
          .filter(
            (user) =>
              user.credentials &&
              user.credentials.includes("teacher") &&
              !teacherIds.includes(user.id)
          )
          .map((t) => ({
            ...t,
            id: t.id,
            full_name: `${t.name}${t.last_name ? ` ${t.last_name}` : ""}`,
          }));

        this.setState({ studentsNotInGroup, teachersNotInGroup,usersInGroup:studentsInGroup,allUsers });
        console.log("setState", this.state);
        return true;
      } else {
        this.setState({ error: "Failed loading users" });
        return false;
      }
    } catch (err) {
      console.error(err);
      this.props.onShowMessage(`Fetching users from server failed.`, "error");
      return false;
    }
  }
  
  populateProgressDetails(assigned_courses,course_progress) {
    return (
      <Typography>
        {Array.isArray(assigned_courses)&&Array.isArray(course_progress)&&assigned_courses.map((item) => {
          const course = course_progress.find(c=>c.course_id === item.course_id) || {};
          return (
            <ul className="tooltip-list">
              <li>
                <span>{item.course_code}</span>
                <span>&nbsp;</span>
                <span>{(course.progress || "0") + "%"}</span>
              </li>
            </ul>
          );
        })}
      </Typography>
    );
  }


  onSelect = (users, type) => {
    // console.debug(model, details);
    switch (type) {
      case "students":
        this.setState({ selectedUsers: users });
        break;
      case "teachers":
        this.setState({ selectedTeachers: users });
        break;
    }
  };

  onAssign = async (students) => {
    const { onAssignStudents } = this.props;
    if (await onAssignStudents(students))
      this.setState({ showAssignForm: false });
  };

  onAssignTeachers = async (teachers) => {
    const { onAssignTeachers } = this.props;
    if (await onAssignTeachers(teachers))
      this.setState({ showAssignTeachersForm: false });
  };

  onRemove = async (students) => {
    const { onUnassignStudents } = this.props;
    if (await onUnassignStudents(students))
      this.setState({ showAssignForm: false });
  };

  onRemoveTeachers = async (teachers) => {
    const { onUnassignTeachers } = this.props;
    if (await onUnassignTeachers(teachers))
      this.setState({ showAssignTeachersForm: false });
  };

  makeUserLink(group_id, user_id) {
    const {userLink = `/groups/${group_id}/%student_id%`} = this.props;
    return strFormat(userLink, {
      "%student_id%": user_id,
    });
  }
  AddTeacherSubmit = async (data) => {
    data = { ...data, credentials: "teacher" };
    const { onShowMessage, onAssignTeachers } = this.props;
    console.debug("submitted:", data);
    const result = await UserService.updateOrganizationUser(data);
    console.log("result", result);
    if (!(result)) {
      onShowMessage(UserService.error || "Unknown error", "error");
      return false;
    }
        // assign teacher to group and relode group
        await onAssignTeachers([result]);

        // clear and close the form
        return true;
    
  };
  render() {
    let {
      id,
      title = "Unknown group",
      isOrganization,
      onAssignStudents,
      // onUnassignStudents,
      students,
      teachers,
    } = this.props;
    console.log("group", this.props);
    students = Array.isArray(students) ? students : [];
    teachers = Array.isArray(teachers) ? teachers : [];
    //console.log("students", students);
    const {
      editing,
      deleting,
      showForm,
      showFormTeacher,
      showAssignForm,
      showAssignTeachersForm,
      studentsNotInGroup,
      teachersNotInGroup,
      selectedUsers,
      selectedTeachers,
      tabSelector,
      onConfirm,
      confirmationText,
      usersInGroup,
      allUsers,
      sortModel,
    } = this.state;
    console.log("tabSelector", tabSelector);
    //console.log("users:",usersInGroup);
    const studentColumns = [
      {
        field: "id",
        hide: true,
      },
      { field: "citizen_id", headerName: "ID", flex: 1 },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        renderCell: (params) => <div>
          {
            [
              <Tooltip
                TransitionComponent={Zoom}
                title={statusDescription(params.row.status_id)}
              >
                <Typography style={{ cursor: "default" }}>
                  {params.value}
                </Typography>
              </Tooltip>,
            ]
          }
        </div>,
      },
      {
        field: "name",
        flex: 1.5,
        headerName: "Full Name",
        valueGetter: (params) =>
          `${params.row.name}${
            params.row.last_name ? ` ${params.row.last_name}` : ""
          }`,
      },
      { field: "email", headerName: "Email", flex: 1.5 },
      {
        field: "progress",
        headerName: "Progress",
        flex: 1.5,
        renderCell: (params) => <div>{params.value}</div>,
      },
      {
        field: "actions",
        flex: 2,
        headerName: "Actions",
        renderCell: (params) => (
          <>
            <ButtonGroup>
              <Button
                variant="contained"
                color="primary"
                size="small"
                component={Link}
                to={this.makeUserLink(id, params.row.id)}
              >
                <Visibility /> View
              </Button>
              {/* {isOrganization && (
                <Button variant="contained" color="secondary" size="small">
                  <Delete /> Remove
                </Button>
              )} */}
              <Button
                onClick={() =>(this.setState({
                  confirmationText: "Are you sure you want to remove this student from group?",
                  onConfirm: async () => this.onRemove([params.id]),
                }))}
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<Delete />}
              >
                Remove
              </Button>
            </ButtonGroup>
          </>
        ),
      },
    ];

    const teacherColumns = [
      {
        field: "id",
        hide: true,
      },
      {
        field: "name",
        flex: 1,
        headerName: "Name",
        valueGetter: (params) =>
          `${params.row.name}${
            params.row.last_name ? ` ${params.row.last_name}` : ""
          }`,
      },
      {
        field: "actions",
        flex: 1,
        headerName: "Actions",
        renderCell: (params) => (
          <>
            <ButtonGroup>
            <Button
                onClick={() =>(this.setState({
                  confirmationText: "Are you sure you want to remove this teacher from group?",
                  onConfirm: async () => {
                    //console.log("deleting ",params)
                    this.onRemoveTeachers([params.id])
                  }
                }))}
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<Delete />}
              >
                Remove
              </Button>
              {/* {isOrganization && (
                <Button variant="contained" color="secondary" size="small">
                  <Delete /> Remove
                </Button>
              )} */}
            </ButtonGroup>
          </>
        ),
      },
    ];

    // console.debug("Group rows", rows)
    const onCloseConfirmDialog = () =>
      this.setState({ onConfirm: null, confirmationText: null });

    //console.log("group test ",students || [], columns)  
    return (
      <div className="group">
        <Typography variant="h1" color="primary">
          {title}
        </Typography>
        <Tabs value={tabSelector}>
          <Tab
            label={`Students (${students.length})`}
            onClick={() => this.setState({ tabSelector: 0 })}
          />
          <Tab
            label={`Teachers (${teachers.length})`}
            onClick={() => this.setState({ tabSelector: 1 })}
          />
        </Tabs>
        <SwipeableViews
          index={tabSelector}
          onChangeIndex={(tabSelector) => this.setState({ tabSelector })}
        >
          <div className={`bubble stretch`}>
            <Typography variant="h3">Students</Typography>
            <div className="flex flex justify-content-space-between">
              <ButtonGroup>
                {isOrganization && (
                  <Button
                    variant="contained"
                    className="add-button bg-green color-white"
                    onClick={() => this.setState({ showForm: true })}
                  >
                    <GroupAdd />
                    Add students
                  </Button>
                )}
                {isOrganization && (
                  <AsyncButton
                    variant="contained"
                    className="add-button bg-orange color-white"
                    onClick={() => this.setState({ showAssignForm: true })}
                  >
                    <GroupAdd />
                    Assign students
                  </AsyncButton>
                )}
              </ButtonGroup>
              <ButtonGroup>
                {isOrganization && selectedUsers.length > 0 && (
                  <Button
                    variant="contained"
                    color="secondary"
                    // onClick={() => this.onRemove(this.state.selectedUsers) }
                    onClick={() =>
                      this.setState({
                        onConfirm: () =>
                          this.onRemove(this.state.selectedUsers),
                        confirmationText: `Are you sure you want to remove ${this.state.selectedUsers.length} students from this group?`,
                      })
                    }
                  >
                    <Remove />
                    Remove selected
                  </Button>
                )}
              </ButtonGroup>
            </div>
            <DataGrid
              loading={!Boolean(students)}
              {...{ rows: usersInGroup || [], columns:studentColumns }}
              autoHeight
              onSelectionModelChange={(model) =>
                this.onSelect(model, "students")
              }
              onSortModelChange={(model) =>{
                if(JSON.stringify(model) !== JSON.stringify(sortModel)){
                  this.setState({sortModel: model})
                }
              } }
              sortModel={sortModel}
              checkboxSelection
            />
          </div>
          <div className={`bubble stretch`}>
            <Typography variant="h3">Teachers</Typography>
            <div className="flex justify-content-space-between">
              <ButtonGroup>
                {isOrganization && (
                  <Button
                    variant="contained"
                    className="add-button bg-green color-white"
                    onClick={() => this.setState({ showFormTeacher: true })}
                  >
                    <GroupAdd />
                    Add teacher
                  </Button>
                )}
                {isOrganization && (
                  <Button
                    variant="contained"
                    className="add-button bg-orange color-white"
                    onClick={() =>
                      this.setState({ showAssignTeachersForm: true })
                    }
                  >
                    <GroupAdd />
                    Assign teachers
                  </Button>
                )}
              </ButtonGroup>
              {isOrganization && selectedTeachers.length > 0 && (
                <ButtonGroup>
                  <Button
                    variant="contained"
                    className="add-button bg-red color-white"
                    // onClick={() => this.onRemoveTeachers(this.state.selectedTeachers) }
                    onClick={() =>
                      this.setState({
                        onConfirm: () =>
                          this.onRemoveTeachers(this.state.selectedTeachers),
                        confirmationText: `Are you sure you want to remove ${this.state.selectedTeachers.length} teachers from this group?`,
                      })
                    }
                  >
                    <Cancel />
                    Remove selected
                  </Button>
                  {/* <Button
                      variant="contained"
                      className="add-button bg-teal color-white"
                      onClick={() =>
                        this.setState({ showAssignTeachersForm: true })
                      }
                    >
                      <Archive />
                      Archive selected
                    </Button>                   */}
                </ButtonGroup>
              )}
            </div>
            <DataGrid
              loading={!Boolean(teachers)}
              {...{ rows: teachers || [], columns:teacherColumns }}
              autoHeight
              onSelectionModelChange={(model) =>
                this.onSelect(model, "teachers")
              }
              checkboxSelection
            />
          </div>
        </SwipeableViews>
        {showForm && (
          <ModalAddStudents
            existingUsers={allUsers}
            onModalOpen={showForm}
            groupId={id}
            onAssign={onAssignStudents}
            onModalClose={() => this.setState({ showForm: false })}
            onChanges={() => {
              this.fetchUsers();
              //this.fetchPrograms();
            }}
          />
        )}
        {showFormTeacher && (
          <TeacherForm
            open={showFormTeacher}
            onSubmit={this.AddTeacherSubmit}
            onClose={() => this.setState({ showFormTeacher: false })}
          />
        )}

        <ModalWindow
          header={"Assign existing students"}
          open={isOrganization && showAssignForm}
          onClose={() => this.setState({ showAssignForm: false })}
        >
          <SelectUsersForm
            {...{ users: studentsNotInGroup }}
            onAssign={this.onAssign}
            onClose={() => this.setState({ showAssignForm: false })}
          />
        </ModalWindow>
        <ModalWindow
          header={"Assign existing teachers"}
          open={isOrganization && showAssignTeachersForm}
          onClose={() => this.setState({ showAssignTeachersForm: false })}
        >
          <SelectUsersForm
            {...{ users: teachersNotInGroup }}
            onAssign={this.onAssignTeachers}
          />
        </ModalWindow>
        <ConfirmDialog
          open={typeof onConfirm === "function"}
          onConfirm={() => {
            onConfirm();
            onCloseConfirmDialog();
          }}
          prompt={confirmationText}
          // clear on close
          onClose={onCloseConfirmDialog}
        />
      </div>
    );
  }
}

export default Group;
