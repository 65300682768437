import React from 'react';
import { Typography } from '@material-ui/core';

const StudentItem = (props) => {
  return (
    <div className="status-item flex column align-items-center">
      <Typography variant='h5'>{props.title}</Typography>
      <Typography>{props.value}</Typography>
    </div>
  );
};

export default StudentItem;
