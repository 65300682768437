import { Avatar, Typography, Grid, Button, Container, ButtonGroup } from '@material-ui/core';
import Spinner from '../../template/Spinner';
import React from 'react'
import "./group-student.scss";
import ModalEditStudent from '../../template/ModalEditStudent';
import PasswordChangeDialog from "@bit/ses-education.ses-courses-shared-components.template.password-change-dialog";

const DetailsRow = (props) => (
    <div className="details-row">
      <span>{props.title}</span>
      <span>{props.value}</span>
    </div>
  );
  
  // const { PUBLIC_URL = "https://images.ses-education.com" } = process.env
  const PUBLIC_URL = "https://images.ses-education.com/courses"
  
class UserDetails extends React.Component{
    state = {
        editModalOpen: false,
        editPasswordModalOpen: false,
    }
    closeEditModal = () => {
        this.setState({
          editModalOpen: false,
        });
    }
    closeEditPasswordModal = () => {
        this.setState({
          editPasswordModalOpen: false,
        });
    }
    onPasswordChangeSubmit = async (password) => {
        const {onPasswordChange, onShowMessage} = this.props
        // this should not happen
        if( !typeof onPasswordChange === "function") return onShowMessage("Wrong handler! Please, reaload page.")
    
        // if successfully changed - close the modal
        if( await onPasswordChange(password ) ) {
          this.closeEditPasswordModal();      
          return true;
        }
        
        // if failed, return false
        return false;
    }    
    render(){
        const { id, name, last_name,user:currentUser, onChange } = this.props;
        const { editModalOpen, editPasswordModalOpen } = this.state;
        const full_name = `${name ? name : "?"}${last_name ? ` ${last_name}` : "" }`;
        const initials = `${name ? name[0] : "?"}${last_name ? last_name[0] : "" }`;        
        
        return (<>
        <div className="user-details">            
            <Typography variant="h1">
                <Avatar 
                style={{backgroundColor: "brown"}} 
                alt={full_name} 
                // src={}
                >{initials}</Avatar>&nbsp;{full_name}
            </Typography>
            {/* {!currentUser && <Spinner/>} */}
            {currentUser && 
            <Container className="user-profile">
                <Grid container className="details-container" spacing={4}>
                    <Grid
                        item
                        lg={3}
                        md={4}
                        sm={4}
                        xs={12}
                        className="image-container"
                    >
                        <img
                            src={`${PUBLIC_URL}/userPlaceholder.png`}
                            alt="user-placeholder"
                            className="image"
                        ></img>
                    </Grid>
                    <Grid item lg={6} md={5} sm={5} xs={12} className="details-text">
                        {[
                          { title: "#", value: currentUser.id },
                          { title: "ID", value: currentUser.citizen_id },
                          { title: "First Name", value: currentUser.name },
                          { title: "Last Name", value: currentUser.last_name },
                          { title: "Email", value: currentUser.email },
                          { title: "Status", value: currentUser.status ? currentUser.status.status_title || "?" : "-"  },
                          { title: "Credentials", value: Array.isArray(currentUser.credential_titles) ? 
                            currentUser.credential_titles.join(", ") : "-"  },
                        ].map((detail) => (
                            <DetailsRow {...detail} />
                        ))}
                    </Grid>
                    <Grid
                        item
                        lg={3}
                        md={3}
                        sm={3}
                        xs={12}
                        className="buttons-container"
                    >
                        <ButtonGroup className="setting-buttons">
                        <Button
                            variant="contained"
                            onClick={() =>
                                this.setState({
                                    editModalOpen: true,
                                })
                            }
                            color="primary"
                        >
                            Edit user
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() =>
                            this.setState({
                              editPasswordModalOpen: true,
                            })
                          }
                          color="secondary"
                        >
                          Change Password
                        </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
                {editModalOpen &&
                    <ModalEditStudent
                        user={currentUser}
                        onModalOpen={editModalOpen}
                        onClose={this.closeEditModal}
                        onUpdate={onChange}
                        {...this.props}
                    />
                }
                <PasswordChangeDialog
                    open={editPasswordModalOpen}
                    onClose={this.closeEditPasswordModal}
                    onSubmit={this.onPasswordChangeSubmit}
                />
            </Container>}
        </div>
        </>
        )
    }
}

export default UserDetails