import Base from "../services/baseClientService";

class OrganizationService extends Base {
  static async createAndAssign(data) {
    try {
      const result = await this.POST(
        `/organization/users/add-and-assign-programs`,
        data
      );
      return result;
    } catch (e) {
      console.error(
        "Error creating users and assigning them to programs: ",
        e.message
      );
      this.Error(e.message);
      return false;
    }
  }

  static async getStatusList() {
    try {
      return await this.POST(`/organization/users/status-list`);
    } catch (e) {
      return this.Error(e.message);
    }
  }

  static async getStudentsProgress() {
    try {
      return await this.GET(`/organization/users/progress`);
    } catch (e) {
      return this.Error(e.message);
    }
  }

  static async getStudentGroups() {
    try {
      return await this.GET(`/organization/users/groups`);
    } catch (e) {
      return this.Error(e.message);
    }
  }

  static async updateStudentGroup( data ) {
    try {
      return await this.POST(`/organization/users/groups`, data );
    } catch (e) {
      return this.Error(e.message);
    }
  }

  static async deleteStudentGroup( id ) {
    try {
      return await this.POST(`/organization/users/groups/delete/${id}` );
    } catch (e) {
      return this.Error(e.message);
    }
  }

  static async archiveStudentGroup( id ) {
    try {
      return await this.POST(`/organization/users/groups/archive/${id}` );
    } catch (e) {
      return this.Error(e.message);
    }
  }

  static async unarchiveStudentGroup( id ) {
    try {
      return await this.POST(`/organization/users/groups/unarchive/${id}` );
    } catch (e) {
      return this.Error(e.message);
    }
  }

  static async assignStudents( id, students ) {
    try {
      return await this.POST(`/organization/users/groups/assign-students/${id}`, {students} );
    } catch (e) {
      return this.Error(e.message);
    }
  }

  static async unassignStudents( id, students ) {
    try {
      return await this.POST(`/organization/users/groups/unassign-students/${id}`, {students} );
    } catch (e) {
      return this.Error(e.message);
    }
  }
  static async assignTeachers( id, teachers ) {
    try {
      return await this.POST(`/organization/users/groups/assign-teachers/${id}`, {teachers} );
    } catch (e) {
      return this.Error(e.message);
    }
  }

  static async unassignTeachers( id, teachers ) {
    try {
      return await this.POST(`/organization/users/groups/unassign-teachers/${id}`, {teachers} );
    } catch (e) {
      return this.Error(e.message);
    }
  }

  static async ExtendedReportExcel( data ) {
    try {
      return await this.POST(`/organization/reports/grades/extended/excel`, {data},{headers:{
        responseType: 'arraybuffer',
      }} );
    } catch (e) {
      return this.Error(e.message);
    }
  }

  static async gradesReportExcel( data ) {
    try {
      return await this.POST(`/organization/reports/grades/excel`, {data},{headers:{
        responseType: 'arraybuffer',
      }} );
    } catch (e) {
      return this.Error(e.message);
    }
  }

  static async programsReportExcel( data ) {
    try {
      return await this.POST(`/organization/reports/programs/excel`, {data},{headers:{
        responseType: 'arraybuffer',
      }} );
    } catch (e) {
      return this.Error(e.message);
    }
  }

  static async getProgramsProgress() {
    return await this.GET(`/organization/programs/progress`);
  }

  static async getOrganizationCourses() {
    const cacheIndex = "organization-courses";
    return this.getCachedOr(cacheIndex, async () => {
      try {
        const result = await this.GET(`/organization/courses`);
        this.cache(cacheIndex, result);
        return result;
      } catch (e) {
        return this.Error(e.message);
      }
    });
  }
}

export default OrganizationService;
