import React from "react";
// import UserService from "../../../services/UserService";
// import ProgramService from "../../../services/ProgramService";
// import AuthService from "../../../services/AuthService";
import {
  // Accordion,
  Grid,
  // AccordionDetails,
  // AccordionSummary,
  Typography,
  Container,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogActions,
  // LinearProgress,
  // TextField,
  // Modal,
} from "@material-ui/core";
import { connect } from "react-redux";
import { showMessage } from "../../../redux/notificationActions";
import ModalEditStudent from "../../template/ModalEditStudent";
import Spinner from "../../template/Spinner";
import { Link } from "react-router-dom";
import "./users.scss";
import PasswordChangeDialog from "@bit/ses-education.ses-courses-shared-components.template.password-change-dialog";
import AutoBreadcrumbs from "../../template/CustomBreadcrumbs";
import Error from "../../template/Error";
import UserProgress from "./user-progress";
import GroupStudentCourse from "../StudentGroups/group-student-course";
import { strFormat } from "../../../utils/functions";
import DeleteIcon from "@material-ui/icons/Delete";
import UserService from "../../../services/UserService";
import { Redirect } from "react-router-dom";


const DetailsRow = (props) => (
  <div className="details-row">
    <span>{props.title}</span>
    <span>{props.value}</span>
  </div>
);

// const { PUBLIC_URL = "https://images.ses-education.com" } = process.env
const PUBLIC_URL = "https://images.ses-education.com/courses"

class UserProfile extends React.Component {
  state = {
    currentUser: null,
    programs: null,
    users: null,
    error: null,    
    editModalOpen: false,
    // renderEditModal: false,
    editPasswordModalOpen: false,
    breadcrumbs: [],
    backLink: "/",
    course: null,
    confirmDialog: false,
    delID:null,
    redirect: null,
  };

  componentDidMount() {
    this.populateCourse();
  }
  async componentDidUpdate(prevProps,prevState) {
    console.log(prevState,this.state)
    if(this.props.match.params.course_id !== prevProps.match.params.course_id
      || prevProps.currentUser !== this.props.currentUser) {
      this.populateCourse();
    }
  }
  async populateCourse(){
    const {programs} = await this.props;
    const {course_id}=this.props.match.params;
    let course = null;
    let program = null;
    if(programs && course_id){
      program = programs.find(p => p.course_ids.includes(parseInt(course_id)));
      if(program){
        course = program.courses.find(c => c.course_id === parseInt(course_id));
      }
    }
    this.setState({course},this.populateBreadCrums);
  }
  populateBreadCrums = () =>{
    const { course } = this.state;
    let {breadcrumbs, backLink = "/"} = this.props;
    breadcrumbs = [...breadcrumbs];
    const {group_id ,user_id,course_id}=this.props.match.params;
    const courseLink = (group_id && user_id) ?`/groups/${group_id}/${user_id}/%course_id%`:`/students/${user_id}/%course_id%`;
    if(course_id && course){
      backLink = `/students/${user_id}`
      breadcrumbs.push({ text: course.title, to: strFormat(courseLink, {
        "%course_id%": course_id })});
    }
    this.setState({breadcrumbs,backLink});
  }
  // async fetchUsers() {
  //   const users = await UserService.getUsers();
  //   let currentUser = null;
  //   if (!users) {
  //     return this.setState({ users: false, error: UserService.error });
  //   }
  //   // if id wasnt passed in the url - its a profile
  //   // if (!currentUser) {
  //   if (!this.props.match.params.user_id) {
  //     currentUser = this.props.reduxUser;
  //   } else {
  //     currentUser = users.find(
  //       (item) => item.id === parseInt(this.props.match.params.user_id)
  //     );
  //   }
  //   console.log("CURRENT USER: ", currentUser);
  //   this.setState({ currentUser, users });
  // }

  // async fetchPrograms() {
  //   try {
  //     const programs = await ProgramService.getPrograms();
  //     if (programs) {
  //       this.setState({
  //         programs,
  //       });

  //       return true;
  //     } else {
  //       this.setState({ error: "Failed loading programs" });
  //       return false;
  //     }
  //   } catch (err) {
  //     this.props.onShowMessage(
  //       `Fetching organization programs from server failed.`,
  //       "error"
  //     );
  //     return false;
  //   }
  // }

  closeEditModal = () => {
    this.setState({
      editModalOpen: false,
      // renderEditModal: false,
      // id: null,
    });
  }

  closeDialogHandler() {
    this.setState({ confirmDialog: false, delID: null });
  }

  closeEditPasswordModal = () => {
    this.setState({
      editPasswordModalOpen: false,
    });
  };

  async deleteUserHandler() {
    const { delID,backLink } = this.state;
    const result = await UserService.deleteOrganizationUser(delID);
    if (result) {
      this.props.onShowMessage(`User with id ${delID} was deleted.`, "info");
      this.setState({ redirect: backLink });
    } else {
      this.props.onShowMessage(
        `Error deleting user, please remove assigned programs before deletion.`,
        "error"
      );
    }
    this.closeDialogHandler();
  }
  
  calculateProgramProgress(courses) {
    // const { currentUser } = this.state;
    const { currentUser } = this.props;

    console.debug( "Courses:", courses )
    if (!Array.isArray(courses) || !courses[0] ) return 0;

    const result = courses.map((c) => {
      const course =
        currentUser.course_progress &&
        currentUser.course_progress.find(
          (item) => item.course_id === c.course_id
        );
      if (!course || course.progress === null) return 0;
      return course.progress;
    });
    let sum = result.reduce(function (sum, value) {
      return sum + value;
    }, 0);

    return Math.round(sum / result.length);
  }


  onPasswordChangeSubmit = async (password) => {
    const {onPasswordChange, onShowMessage} = this.props
    // this should not happen
    if( !typeof onPasswordChange === "function") return onShowMessage("Wrong handler! Please, reaload page.")

    // if successfully changed - close the modal
    if( await onPasswordChange(password ) ) {
      this.closeEditPasswordModal();      
      return true;
    }

    // if failed, return false
    return false;
  }

  render() {
    const {
      currentUser,
      programs,
      users,
      error,
      onPasswordChange,
      onStudentUpdate,
    } = this.props
    //console.log("UserProfile: ", this.props);
    const {group_id ,user_id,course_id}=this.props.match.params;
    const courseLink = (group_id && user_id) ?`/groups/${group_id}/${user_id}/%course_id%`:`/students/${user_id}/%course_id%`;
    const {
      // renderEditModal,
      editModalOpen,
      editPasswordModalOpen,
      breadcrumbs,
      backLink,
      course,
      redirect,
      confirmDialog,
      delID,
    } = this.state;
    const course_progress = {};
    // if onStudentUpdate is set, it's a student profile, not Organization user
    const isStudentProfile = Boolean(onStudentUpdate);
    const renderEditModal = isStudentProfile;

    // console.debug("user render currentUser", currentUser)
    if ( !error && (!currentUser || !programs) ) return <Spinner />;
    if( error ) return <Error error={error} />    
    if (redirect) return <Redirect to={redirect} />;
    return (
      <Container className="user-profile">
        {breadcrumbs && <AutoBreadcrumbs items={breadcrumbs} {...{backLink}} />}
        { course_id && !course && <Spinner /> }
        { course_id && course && course_progress && <GroupStudentCourse {...{user_id, course, programs, course_progress}} /> }
        {!course_id &&(<>
          <Typography variant="h1" color="primary">
              User profile
          </Typography>
          <Grid container className="details-container" spacing={4}>
              <Grid
                item
                lg={3}
                md={4}
                sm={4}
                xs={12}
                className="image-container"
              >
                <img
                  src={`${PUBLIC_URL}/userPlaceholder.png`}
                  alt="user-placeholder"
                  className="image"
                ></img>
              </Grid>
              <Grid item lg={6} md={5} sm={5} xs={12} className="details-text">
                {[
                  { title: "#", value: currentUser.id },
                  { title: "ID", value: currentUser.citizen_id },
                  { title: "First Name", value: currentUser.name },
                  { title: "Last Name", value: currentUser.last_name },
                  { title: "Email", value: currentUser.email },
                  { title: "Status", value: currentUser.status ? currentUser.status.status_title || "?" : "-"  },
                  { title: "Credentials", value: Array.isArray(currentUser.credential_titles) ? 
                  currentUser.credential_titles.join(", ") : "-"  },
                ].map((detail) => (
                  <DetailsRow {...detail} />
                ))}
              </Grid>
              <Grid
                item
                lg={3}
                md={3}
                sm={3}
                xs={12}
                className="buttons-container"
              >
                {/* <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={ backLink }
                >
                  Back
                </Button> */}
                <ButtonGroup className="setting-buttons">
                {
                  isStudentProfile && <Button
                  variant="contained"
                  onClick={() =>
                    this.setState({
                      editModalOpen: true,
                    })
                  }
                  color="primary"
                >
                  Edit user
                </Button>
                }
                <Button
                  variant="contained"
                  onClick={() =>
                    this.setState({
                      editPasswordModalOpen: true,
                    })
                  }
                  color="secondary"
                >
                  Change Password
                </Button>
                </ButtonGroup>
                <Button
                  onClick={() =>
                    this.setState({ confirmDialog: true, delID: user_id })
                  }
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                >
                  Delete
                </Button>
              </Grid>
          </Grid>
          <Dialog
            open={confirmDialog}
            onClose={() => this.closeDialogHandler()}
          >
            <DialogTitle>
              {`Are you sure you want to delete user with id ${delID} ?`}
            </DialogTitle>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.closeDialogHandler()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => this.deleteUserHandler()}
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          {/* <Grid container xs={5} className="details-container">
            <Grid item xs={4}>
              {currentUser.citizen_id ? (
                <Typography className="details-text">User ID:</Typography>
              ) : null}
              <Typography className="details-text">First Name:</Typography>
              <Typography className="details-text">Last Name:</Typography>
              <Typography className="details-text">Email:</Typography>
            </Grid>
            <Grid item xs={8}>
              {currentUser.citizen_id ? (
                <Typography className="details-text">
                  {currentUser.citizen_id}
                </Typography>
              ) : null}
              <Typography className="details-text">
                {currentUser.name}
              </Typography>
              <Typography className="details-text">
                {currentUser.last_name}
              </Typography>
              <Typography className="details-text">
                {currentUser.email}
              </Typography>
            </Grid>
          </Grid>
          <Grid container xs={3} className="image-container">
            <img
              src={`${process.env.PUBLIC_URL}/static/images/userPlaceholder.jpg`}
              alt="user-placeholder"
              className="image"
            ></img>
          </Grid>
          <Grid container xs={4} className="status-credentials-container">
            <Grid item xs={12} className="status">
              <Typography>User Status:</Typography>
              <Typography>{currentUser.status.status_title}</Typography>
            </Grid>
            <Grid item xs={12} className="credentials">
              <Typography align="center">User Credentials</Typography>
              {currentUser.credential_titles.map((cred) => (
                <Typography className="list-text">
                  {"-" + " " + cred}
                </Typography>
              ))}
            </Grid>
          </Grid>
          <Grid container xs={12} className="buttons-container">
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={"/students"}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "15px" }}
              onClick={() =>
                this.setState({
                  editPasswordModalOpen: true,
                })
              }
            >
              Change Password
            </Button>
            <Button
              variant="contained"
              onClick={() =>
                this.setState({
                  editModalOpen: true,
                  renderEditModal: true,
                })
              }
              color="secondary"
              style={{ margin: "15px" }}
            >
              Edit User
            </Button>
          </Grid> */}
          {currentUser.programs && ( <UserProgress 
            {...{currentUser, programs,courseLink}}
           />
            // <>
            // <Typography variant="h2">Programs</Typography>
            // <div className="progress-container">
            //   {currentUser.programs.map((program) => {
            //     // find the right program in state programs
            //     const courses = programs.find(
            //       (p) => p.program_id === program.program_id
            //     ).courses;

            //     // calculate program progress %
            //     const progress = this.calculateProgramProgress(courses);

            //     return (
            //       <Accordion className="accorion-item" defaultExpanded={false}>
            //         <AccordionSummary
            //           expandIcon={<ExpandMoreIcon />}
            //           id="accordion-title"
            //         >
            //           <Typography
            //             id="accordion-title-text"
            //             style={{
            //               fontWeight: "bolder",
            //             }}
            //           >
            //             {program.title}
            //           </Typography>
            //           <Typography
            //             id="accordion-title-text"
            //             style={{
            //               fontWeight: "bolder",
            //             }}
            //           >
            //             {progress + "%"}
            //           </Typography>
            //         </AccordionSummary>

            //         <AccordionDetails className="accorion-details">
            //           {courses[0] === null ? (
            //             <Typography>No courses</Typography>
            //           ) : (
            //             courses.map((c) => {
            //               const course =
            //                 currentUser.course_progress &&
            //                 currentUser.course_progress.find(
            //                   (item) => item.course_id === c.course_id
            //                 );

            //               return (
            //                 <div className="line">
            //                   <Typography>{c.title}</Typography>

            //                   {course ? (
            //                     course.progress === 100 ? (
            //                       <Typography className="score-container">
            //                         {course.total_score}
            //                         <EmojiEventsIcon className="grade-icon" />
            //                       </Typography>
            //                     ) : (
            //                       <div className="score-container">
            //                         <LinearProgress
            //                           className="progress-bar"
            //                           variant="determinate"
            //                           value={course.progress}
            //                         />
            //                       </div>
            //                     )
            //                   ) : (
            //                     <div className="score-container">
            //                       <LinearProgress
            //                         className="progress-bar"
            //                         variant="determinate"
            //                         value={0}
            //                       />
            //                     </div>
            //                   )}
            //                 </div>
            //               );
            //             })
            //           )}
            //         </AccordionDetails>
            //       </Accordion>
            //     );
            //   })}
            // </div>
            // </>
          )}

        {renderEditModal && (
          <ModalEditStudent
            user={currentUser}
            onModalOpen={editModalOpen}
            onClose={this.closeEditModal}
            onUpdate={onStudentUpdate}
          />
        )}
        <PasswordChangeDialog
          open={editPasswordModalOpen}
          onClose={this.closeEditPasswordModal}
          onSubmit={this.onPasswordChangeSubmit}
        />
      </>)}
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch(showMessage(message, type)),
  };
};

const stateToProps = (state) => ({ reduxUser: state.auth.user });

export default connect(stateToProps, mapDispatchToProps)(UserProfile);
