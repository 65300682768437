import React from "react";
import UserService from "../../../services/UserService";
import ProgramService from "../../../services/ProgramService";
import AdminTable from "@bit/ses-education.ses-courses-shared-components.admin-table";
import {
  LinearProgress,
  Grid,
  ButtonGroup,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  Tooltip,
  Zoom,
  Container,
  MenuItem,
  Select,
} from "@material-ui/core";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import PersonIcon from "@material-ui/icons/Person";
import DeleteIcon from "@material-ui/icons/Delete";
import { DataGrid, GridOverlay } from "@material-ui/data-grid";
import { connect } from "react-redux";
import { showMessage } from "../../../redux/notificationActions";
import ModalAddStudents from "../../template/ModalAddStudents";
import { statusDescription } from "../../../data/statusDescription";
import { Link } from "react-router-dom";
import Error from "../../template/Error";
import AutoBreadcrumbs from '../../template/CustomBreadcrumbs';
import "./users.scss";
import { Home } from "@material-ui/icons";
import WithTranslations from "../../WithTranslations";
import { strFormat } from "../../../utils/functions";

const filterFunctions = {
  status_id : (value) => (row) => {
    return row.status_id === value;
  },
};
class Users extends React.Component {
  state = {
    id: null,
    users: [],
    programs: null,
    rows: [],
    filter_rows: [],
    loading: false,
    confirmDialog: false,
    error: null,
    addModalOpen: false,
    renderAddModal: false, // triggers the rendering of ModalAddStudents
    user: null,
    filters: {
      status_id: -1,
      selectedOnly:{
        items: [],
      }
    },
    sortModel: [{ field: "status", sort:"asc" },{ field: "user_full_name", sort:"asc" }],
  };

  async componentDidMount() {
    this.props.fetchTranslations([
      "Are you sure you want to delete %number% student(s)?",
    ]);
    this.setState({ loading: true });
    const usersLoaded = await this.fetchUsers();
    const programsLoaded = await this.fetchPrograms();
    if (usersLoaded && programsLoaded) {
      this.setState({ error: null, loading: false });
    }
    this.onSelect({target:{value:-1}},"status_id");
  }

  async fetchUsers() {
    try {
      console.log("fetching users");
      let users = await UserService.getUsers();

      if (users) {
        // only populate state with STUDENT users
        const studentUsers = users.filter((user) =>
          user.credentials && user.credentials.includes("student")
        );
        this.setState({ users: studentUsers });
        this.populateRows(studentUsers);
        return true;
      } else {
        this.setState({ error: "Failed loading users" });
        return false;
      }
    } catch (err) {
      this.props.onShowMessage(`Fetching users from server failed.`, "error");
      return false;
    }
  }

  async fetchPrograms() {
    try {
      const programs = await ProgramService.getPrograms();
      if (programs) {
        this.setState({
          programs,
        });
        return true;
      } else {
        this.setState({ error: "Failed loading programs" });
        return false;
      }
    } catch (err) {
      this.props.onShowMessage(
        `Fetching organization programs from server failed.`,
        "error"
      );
      return false;
    }
  }

  /**
   * populates users and stores "rows" array to state
   * @param {*} users
   */
  populateRows(users) {
    let rows = [];
    users.forEach((u) => {
      const item = {
        id: u.id, // must exist
        citizen_id: u.citizen_id ? u.citizen_id : "N/A",
        status_id: u.status_id,
        status: u.status.status_title,
        user_full_name: `${u.name} ${u.last_name}`,
        user_email: u.email,
        progress: [
          u.courses_total ? (
            <Tooltip
              TransitionComponent={Zoom}
              title={this.populateProgressDetails(u.assigned_courses || [] , u.course_progress || [])}
              arrow
            >
              <Typography style={{ cursor: "default" }}>
                {(u.courses_done || 0) + "/" + u.courses_total}
              </Typography>
            </Tooltip>
          ) : (
            <Typography style={{ cursor: "default" }}>{"N/A"}</Typography>
          ),
        ],
      };
      rows.push(item);
    });
    this.setState({ rows, filter_rows: rows });
  }

  loadingOverlay() {
    return (
      <GridOverlay>
        <div style={{ position: "absolute", top: 0, width: "100%" }}>
          <LinearProgress />
        </div>
      </GridOverlay>
    );
  }

  populateProgressDetails(assigned_courses,course_progress) {
    return (
      <Typography>
        {assigned_courses.map((item) => {
          const course = course_progress.find(c=>c.course_id === item.course_id) || {};
          return (
            <ul className="tooltip-list">
              <li>
                <span>{item.course_code}</span>
                <span>&nbsp;</span>
                <span>{(course.progress || "0") + "%"}</span>
              </li>
            </ul>
          );
        })}
      </Typography>
    );
  }

  closeDialogHandler() {
    this.setState({ confirmDialog: false, delID: null });
  }

  async deleteUserHandler() {
    const { filters } = this.state;
    const result = await UserService.deleteStudents(filters.selectedOnly.items);
    if (result) {
      this.props.onShowMessage(`Users with ids ${filters.selectedOnly.items} was deleted.`, "info");
    } else {
      this.props.onShowMessage(
        UserService.error || `Error deleting users, please remove assigned programs before deletion.`,
        "error"
      );
    }
    this.fetchUsers();
    this.closeDialogHandler();
  }

  closeAddModal() {
    this.setState({
      addModalOpen: false,
      renderAddModal: false,
    });
  }
  onSelect2 = (model) => {
    let { filters } = this.state;
    filters.selectedOnly.items = model;
    this.setState({ filters });
  }
  onSelect =  (event, type) => {
    let { filters } = this.state;
    filters = { ...filters, [type]: event.target.value }
    console.log("onSelect",filters)
    this.setState( {filters}, this.filterEverything );
  }
  filterEverything = () => {
    console.log("filterEverything",this.state);
    const { filters } = this.state;
    let filter_rows = [...this.state.rows];
    Object.entries(filters).map((entry) => {
      const [key, value] = entry;
      //console.log("filterEverything",filterFunctions[key]);
      if(filterFunctions[key] && value !== -1){
        filter_rows = filter_rows.filter(filterFunctions[key](value));
      }
    });
    this.setState({ filter_rows });
  }
  render() {
    const {
      loading,
      filter_rows,
      error,
      confirmDialog,
      addModalOpen,
      users,
      renderAddModal,
      filters,
      sortModel,
    } = this.state;
    const { _t } = this.props;
    const columns = [
      { field: "citizen_id", headerName: "ID", flex: 1 },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        renderCell: (params) => <div>
          {
            [
              <Tooltip
                TransitionComponent={Zoom}
                title={statusDescription(params.row.status_id)}
              >
                <Typography style={{ cursor: "default" }}>
                  {params.value}
                </Typography>
              </Tooltip>,
            ]
          }
        </div>,
      },
      { field: "user_full_name", headerName: "Full Name", flex: 1.5 },
      { field: "user_email", headerName: "Email", flex: 1.5 },
      {
        field: "progress",
        headerName: "Progress",
        flex: 1.5,
        renderCell: (params) => <div>{params.value}</div>,
      },
      {
        field: "actions",
        headerName: "Actions",
        flex: 2,
        renderCell: (params) => (
          <div>
            <ButtonGroup>
              <Button
                component={Link}
                to={`/students/${params.id}`}
                variant="contained"
                color="primary"
                size="small"
                startIcon={<PersonIcon />}
              >
                View
              </Button>
              {/* <Button
                onClick={() =>
                  this.setState({ confirmDialog: true, delID: params.id })
                }
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button> */}
            </ButtonGroup>
          </div>
        ),
      },
    ];
    const buttons = 
    <div className="buttons">
      <Button
        onClick={() =>
          this.setState({ confirmDialog: true })
        }
        variant="contained"
        color="secondary"
        size="small"
        disabled={filters.selectedOnly.items.length === 0}
        startIcon={<DeleteIcon />}
      >
        Delete
      </Button>
      <Button
        onClick={() =>
          this.setState({
            addModalOpen: true,
            renderAddModal: true,
          })
        }
        variant="contained"
        startIcon={<GroupAddIcon />}
        className="add-button"
      >
        Add students
      </Button>
      <div>
        <Select
          // native
          value={filters["status_id"]}
          onChange={(event) => this.onSelect(event,"status_id")}
          label='Show:'
        >
          <MenuItem value={"active"}>Active</MenuItem>
          <MenuItem value={"suspended"}>Suspended</MenuItem>
          <MenuItem value={"created"}>Registered</MenuItem>
          <MenuItem value={"archived"}>Dormant</MenuItem>
          <MenuItem value={-1}>All</MenuItem>
        </Select>
      </div>
    </div>;
    return error ? (
      <Error {...{ error }} />
    ) : (
      // <Typography>{error}</Typography>
      <Container className="user-list">
        <AutoBreadcrumbs items={[
          { icon: <Home/> , to: "/"},
          { text: "Students"}
        ]}/>
        <Typography variant="h1" color="primary">Students</Typography>
        <div className="bubble">
            <AdminTable
              components={{
                LoadingOverlay: this.loadingOverlay,
              }}
              {...{columns, buttons,loading}}
              pageSize={10}
              rows = {filter_rows}
              rowHeight={70}
              onSortModelChange={(model) =>{
                if(JSON.stringify(model) !== JSON.stringify(sortModel)){
                  this.setState({sortModel: model})
                }
              } }
              onSelectionModelChange={(model) => {
                return this.onSelect2(model);
              }}
              sortModel={sortModel}
              autoHeight
              searchFields = {["user_full_name", "user_email"]}
              disableSelectionOnClick
              checkboxSelection
            />
            <Dialog
              open={confirmDialog}
              onClose={() => this.closeDialogHandler()}
            >
              <DialogTitle>
                {strFormat(
                    _t("Are you sure you want to delete %number% student(s)?"),
                    { "%number%": filters.selectedOnly.items.length },
                )}
              </DialogTitle>
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.closeDialogHandler()}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => this.deleteUserHandler()}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
            {renderAddModal ? (
              <ModalAddStudents
                existingUsers={users}
                onModalOpen={addModalOpen}
                onModalClose={() => this.closeAddModal()}
                onChanges={() => {
                  this.fetchUsers();
                  this.fetchPrograms();
                }}
              />
            ) : null}
          </div>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch(showMessage(message, type)),
  };
};

export default WithTranslations( connect(null, mapDispatchToProps)(Users) );
