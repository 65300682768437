import { Button, TextField, 
  // Typography 
} from "@material-ui/core";
import React from "react";
// import Spinner from "../../template/Spinner";
import ModalWindow from "@bit/ses-education.ses-courses-shared-components.modal-window";

const defaultState = {
  group: {},
  original: null,
  error: null,
}

class GroupForm extends React.Component {
  state = {
    ...defaultState
  };

  componentDidMount() {
    this.dataToState();
  }
  componentDidUpdate( prevProps) {
      if( prevProps.user !== this.props.user)
        this.dataToState();
  }

  dataToState() {
    const { group = {} } = this.props;
    this.setState({ group, original: { ...group } });
  }

  setValue = ({ target }) => {
    const { name, value, check } = target;

    let { group } = this.state;

    group = { ...group, [name]: value };
    this.setState({ group });
  };

  validate(ev){
    const autovalidated = ev.currentTarget.reportValidity();

    // TODO: check values of each field
    return autovalidated;
  }

  onFormSubmit = async (ev) => {
    // stop sending the form
    ev.preventDefault();

    const {onSubmit, onClose} = this.props;

    // console.debug("form data:", this.state.group)
    // validate form and call callback
    if( this.validate(ev) && typeof onSubmit === "function" && await onSubmit( this.state.group )){
        // calback returned true - clear and close the form
        this.setState({
          ...defaultState
        })

        if( typeof onClose === "function" ) onClose();
    }
  }

  render() {
    const { onSubmit, onClose, ...other } = this.props;
    const { group } = this.state;
    
    if( !other.open ) return null;
      

    const { id, title, description } = group;

    const buttons = <>
    <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
            <Button variant="contained" color="secondary" onClick={onClose}>
              Cancel
            </Button>
    </>

    return (
      <ModalWindow 
      formClassName="group-form" header={id ? `Edit group #${id}` : "Create new group"} 
      onSubmit={this.onFormSubmit}
      {...{...other, buttons, onClose}} 
      >                  
          {id && <div className="id-value">#{id}</div>}
          <TextField
            label="Title"
            name="title"
            value={title || ""}
            onChange={this.setValue}
            fullWidth
            required
          />          
          <TextField
            label="Description (optional)"
            name="description"
            type="text"
            multiline            
            value={description || ""}
            onChange={this.setValue}
            fullWidth
          />                  
      </ModalWindow>
    );
  }
}

export default GroupForm;
