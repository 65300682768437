import { Container, Typography } from '@material-ui/core';
import React from 'react'
import ReportsStudentsList from './reports-students-list';
import "./style.scss";
import AutoBreadcrumbs from '../../../template/CustomBreadcrumbs';
import { Home } from "@material-ui/icons";
class ReportsStudents extends React.Component{
    state = {
        breadcrumbs: [],
        title: null,
    }
    componentDidMount() {
        this.prepState();
    }
    componentDidUpdate(prevProps) {
        if(prevProps.match.params.report !== this.props.match.params.report){
            this.prepState();
        }
    }
    prepState = () => {
        let breadcrumbs = [
            { icon: <Home/> , to: "/"},
        ];
        const {report} = this.props.match.params;
        let title = "";
        switch(report){
            case "students":
                breadcrumbs.push({text: "Reports", to: "/reports"});
                breadcrumbs.push({text: "Grade Report"});
                break;
            case "programs":
                breadcrumbs.push({text: "Reports", to: "/reports"});
                breadcrumbs.push({text: "Programs Report"});
                break;
            case "old":
                breadcrumbs.push({text: "Reports", to: "/reports"});
                breadcrumbs.push({text: "Extended Student Report"});
                break;
            default:
                title = "Reports";
                breadcrumbs.push({text: "Reports"});
                break;
        }
        this.setState({breadcrumbs,title});
    }
    render(){
        const {report} = this.props.match.params;
        const {breadcrumbs,title} = this.state;
        return(
            <Container>
                <AutoBreadcrumbs 
                items={breadcrumbs}
                backLink={report?`/reports`:null}
                />
                {!report &&<Typography variant="h1" color="primary">{title}</Typography>}
                <ReportsStudentsList report = {report}/>
            </Container>
        );
    }
}

export default ReportsStudents