import Base from "../services/baseClientService";

class ProgramService extends Base {
  static async getPrograms() {
    const cacheIndex = "all-programs";
    return await this.getCachedOr(cacheIndex, async () => {
      try {
        const programs = await this.GET(`/organization/programs`);
        this.cache(cacheIndex, programs);
        return programs;
      } catch (e) {
        console.error("Error fetching organization programs: ", e.message);
        this.Error(e.message);
        return false;
      }
    });
  }

  static async unassignProgramFromUser(user_id, program_id) {
    try {
      const result = await this.POST(
        `/organization/users/${user_id}/remove-program/${program_id}`
      );
      return result;
    } catch (e) {
      console.error("Error unassigning program from user: ", e.message);
      this.Error(e.message);
      return false;
    }
  }

  static async assignProgramToUser(user_id, program_id) {
    try {
      const result = await this.POST(
        `/organization/users/${user_id}/assign-program/${program_id}`
      );
      return result;
    } catch (e) {
      console.error(
        "Error assigning program to user within organization: ",
        e.message
      );
      this.Error(e.message);
      return false;
    }
  }
}

export default ProgramService;
